import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ContentSwitcherUtils} from '@application/helper/utilities/content-switcher/content-switcher-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {IdName} from '@domain/id-name';
import {HttpStockLocationService} from '@infrastructure/http/stock-location/http-stock-location.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  ContentSwitcherDialogService,
  ContentSwitcherEntry,
  DialogBuilderFactoryService,
  DialogType,
  FormValidationHelper,
  PrototypeRouteUtils,
  SaveType,
  TabsMenuItem,
  TranslateService
} from '@vdw/angular-component-library';
import {finalize, Observable, takeUntil} from 'rxjs';
import {StockLocationSelectionService} from '../stock-location-selection.service';
import {AddStockLocationNavigationData} from './add-stock-location-navigation-data.interface';
import {StockLocationForm} from './add-stock-location.form';

@Component({
  selector: 'app-add-stock-location',
  templateUrl: './add-stock-location.component.html'
})
export class AddStockLocationComponent extends BaseComponent implements OnInit {
  public addStockLocationForm: StockLocationForm;
  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_STOCK_LOCATION;
  public selectedMenuItem: TabsMenuItem = {value: 0, translationKey: 'GENERAL.GENERAL'};
  public readonly STOCK_LOCATION_TRANSLATION_KEY = 'UTILITIES.TOOL.STOCK_LOCATION';
  public stockLocationId: number = null;

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly stockLocationService: HttpStockLocationService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly contentSwitcher: ContentSwitcherDialogService<AddStockLocationNavigationData>,
    private readonly stockLocationSelectionService: StockLocationSelectionService,
    private readonly textileService: TextileService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setFormFields();
    this.getStockLocationDetails();
  }

  public isEditingStockLocation(): boolean {
    return PrototypeRouteUtils.isEditPath(this.contentSwitcher.activeEntry?.routeData?.route);
  }

  public cancel(): void {
    this.contentSwitcher.navigateBack();
  }

  public getLoadEntry(): ContentSwitcherEntry {
    const editRoute = ContentSwitcherUtils.getEditStockLocationEntry();
    return {...editRoute, data: {routeId: this.addStockLocationForm?.controls?.name?.errors?.entityId}};
  }

  public save(saveType: SaveType): void {
    const isValid = new FormValidationHelper().checkForm(this.addStockLocationForm, this.document);
    if (isValid) {
      this.saving = true;
      const stockLocationToSave = this.getCurrentStockLocation();

      const request: Observable<void | number> = this.isEditingStockLocation() ? this.stockLocationService.update(stockLocationToSave) : this.stockLocationService.save(stockLocationToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) => {
          if (saveType === SaveType.SAVE_AND_CLOSE) {
            stockLocationToSave.id = id;
            this.stockLocationSelectionService.setSelected(stockLocationToSave);

            this.contentSwitcher.navigateBack();
          } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
            this.resetFormFields();
          }
        },
        error: (errorMessage: BackendError) =>
          this.showErrorDialogForBackendError(this.isEditingStockLocation() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  private getStockLocationDetails(): void {
    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.getActiveEntryData()?.stockLocation)) {
      this.selectedMenuItem = this.contentSwitcher.getActiveEntryData().activeTab;
      this.stockLocationId = this.contentSwitcher.getActiveEntryData().stockLocation.id;
      this.setFormValues(this.contentSwitcher.getActiveEntryData().stockLocation);
      return;
    }

    const id = PrototypeRouteUtils.isAddPath(this.contentSwitcher.activeEntry?.routeData?.route) ? null : this.contentSwitcher.getActiveEntryData()?.routeId ?? null;

    if (!AssertionUtils.isNullOrUndefined(id)) {
      this.stockLocationService
        .getById(Number(id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((stockLocation: IdName) => {
          this.stockLocationId = stockLocation.id;

          this.setFormValues(stockLocation);
        });
    }
  }

  private setFormValues(stockLocation: IdName): void {
    this.addStockLocationForm.reset({
      name: stockLocation.name
    });
  }

  private getCurrentStockLocation(): IdName {
    return new IdName(this.isEditingStockLocation() ? this.stockLocationId : null, this.addStockLocationForm.value.name);
  }

  private setFormFields(): void {
    this.addStockLocationForm = this.formBuilder.group({
      name: this.formBuilder.control('', Validators.required, AsyncUniqueValidator.createValidator(this.stockLocationService, null))
    });
  }

  private resetFormFields(): void {
    this.addStockLocationForm.reset();
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {object: this.translate.instant(this.STOCK_LOCATION_TRANSLATION_KEY)}),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }
}
