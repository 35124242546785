import {Injectable, Optional} from '@angular/core';
import {L10nIntlService} from 'angular-l10n';
import {DateTimePipe} from '../custom-pipes/date-time-pipe/date-time.pipe';
import {TranslateService} from '../translation/translate.service';
import {ColDefBuilder} from './col-def-builder';

@Injectable({providedIn: 'root'})
export class ColDefBuilderFactoryService {
  public constructor(
    private readonly l10nIntlService: L10nIntlService,
    private readonly translate: TranslateService,
    @Optional() private readonly dateTimePipe: DateTimePipe
  ) {}

  public getBuilder(): ColDefBuilder {
    return new ColDefBuilder(this.l10nIntlService, this.translate, this.dateTimePipe);
  }
}
