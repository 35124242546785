import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizedRouteFactory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Permission} from '@domain/profile/permission.enum';
import {NotFoundPageComponent} from '../status-codes/404/not-found-page.component';

export const calendarRoutes: Routes = [
  {path: '', component: NotFoundPageComponent},
  AuthorizedRouteFactory.createModuleRoute(RouteUtils.paths.calendar.shift, (): any => import('@presentation/pages/calendar/shift/shift.module').then((m: any) => m.ShiftModule)),
  AuthorizedRouteFactory.createPrototypeModuleRoute(
    {
      route: 'pattern',
      parentId: NavigationId.CALENDAR,
      id: NavigationId.PATTERN_MODULE,
      navigationKey: 'GENERAL.PATTERN.PATTERN',
      requiredPermission: Permission.SHIFT_PATTERN_VIEW
    },
    import('@presentation/pages/calendar/pattern/pattern.module')
  )
];

export const calendarRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(calendarRoutes);
