import {unitOfTime} from 'moment';

export class TimeUtils {
  public static readonly HOURS_IN_A_DAY = 24;
  public static readonly SECOND_IN_MS = 1000;
  public static readonly SECONDS_IN_A_MINUTE = 60;
  public static readonly MINUTES_IN_AN_HOUR = 60;
  public static readonly MINUTE_IN_MS = TimeUtils.SECOND_IN_MS * TimeUtils.SECONDS_IN_A_MINUTE;
  public static readonly HOUR_IN_MS = TimeUtils.MINUTE_IN_MS * TimeUtils.MINUTES_IN_AN_HOUR;
  public static readonly SECONDS_IN_A_HOUR = TimeUtils.MINUTES_IN_AN_HOUR * TimeUtils.SECONDS_IN_A_MINUTE;

  public static readonly MILLISECONDS_UNIT: unitOfTime.Base = 'milliseconds';

  /**
   * Converts a given datetime object to a UTC date object.
   *
   * @param date - The date to be converted.
   * @returns The UTC date object with the time dismissed
   * example: 2021-08-25T02:13:22+02:00  => 2021-08-25T00:00:00.000Z
   */
  public static convertToUTCDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }
}
