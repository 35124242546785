<section class="flex-column full-height bms-theme-content">
  <vdw-editor-header
    [identifier]="HEADER_IDENTIFIER"
    [menuItems]="[selectedMenuItem]"
    [selectedMenuItem]="selectedMenuItem"
    [isCreateNewPage]="!isEditingRawMaterial()"
    [canShowSettings]="false"
    [canShowDelete]="false"
    [canShowDuplicate]="false"
    [canShowSave]="false"
    [inProgress]="saving"
    [disableSaveButton]="isRawMaterialUsed"
    (cancelEvent)="cancel()"
    (saveAndCreateNewEvent)="save(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (saveAndCloseEvent)="save(SAVE_TYPE.SAVE_AND_CLOSE)"
    (selectedMenuItemChange)="selectedMenuItem = $event"
  ></vdw-editor-header>

  <div class="bms-theme flex-column p-48">
    <form [formGroup]="addRawMaterialForm" class="flex-column" vdwBp.gt-sm="pr-64 half-width" vdwBp.lt-sm="full-width">
      <vdw-string-input
        [label]="'GENERAL.NAME' | bmsTranslate"
        formControlName="name"
        [placeholder]="'GENERAL.NAME_PLACEHOLDER' | bmsTranslate: {object: RAW_MATERIAL_TRANSLATION_KEY | bmsTranslate | lowercase}"
      >
        <ng-container error>
          <vdw-invalid-form-message *vdwCanShowErrorForFormControl="'name'; error: 'required'" [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          <vdw-invalid-form-message
            *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'"
            [message]="'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate"
            [link]="getLoadLink()"
            [compact]="true"
          ></vdw-invalid-form-message>
        </ng-container>
      </vdw-string-input>
    </form>
  </div>
</section>
