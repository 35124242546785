import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {IdName} from '@domain/id-name';
import {CheckName} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpStockLocationService implements UniqueIdentifierObjectRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public save(item: IdName): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}stock-locations`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: IdName): Observable<void | number> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}stock-locations/${item.id}`, item.toJSON());
  }

  public getById(id: number): Observable<IdName> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}stock-locations/${id}`).pipe(map((toolJSON: any) => IdName.fromJSON(toolJSON)));
  }

  public isIdentifierAvailable(identifier: string): Observable<CheckName> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}stock-locations/check-name?name=${identifier}`).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public delete(id: number | string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}stock-locations/${id}`);
  }
}
