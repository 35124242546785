import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {RuntimeApplicationEnvironment} from '@application/configuration/runtime-application-environment';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '@environments/environment';
import {Authentication} from '@infrastructure/http/authentication/authentication';
import {HttpGridSettingsService} from '@infrastructure/http/grid-settings/http-grid-settings.service';
import {CustomSignalrHttpClient} from '@infrastructure/signalr/custom-signalr-http-client';
import {MessageHeaders} from '@microsoft/signalr';
import {GridOptionsBuilderFactoryService, LanguageService, SignalrHubConnectionFactoryService, SignalrLogger, TranslateService} from '@vdw/angular-component-library';
import {L10nLoader} from 'angular-l10n';
import {firstValueFrom} from 'rxjs';

export function initializeApplicationEnvironment(applicationEnvironment: RuntimeApplicationEnvironment): () => Promise<void> {
  return (): Promise<void> => {
    return applicationEnvironment.loadEnvironment();
  };
}

@Injectable()
export class InitializeL10n implements L10nLoader {
  public constructor(private languageService: LanguageService) {}

  public init(): Promise<void> {
    return this.languageService.initialize();
  }
}

export function initializeNavigationHistory(): () => void {
  const navigationHistory = inject(NavigationHistoryService);

  return (): void => {
    navigationHistory.subscribeToRouterEvents();
  };
}

export function initializeSignalrHubConnectionFactory(backendConfiguration: BackendConfiguration, authentication: Authentication, signalrLogger: SignalrLogger): SignalrHubConnectionFactoryService {
  return new SignalrHubConnectionFactoryService(
    signalrLogger,
    new CustomSignalrHttpClient((headers: MessageHeaders) => {
      headers['SubscriptionId'] = backendConfiguration.getSubscriptionId();
      headers['CompanyId'] = backendConfiguration.getCompanyId();
      headers['BrowserInstanceId'] = backendConfiguration.getBrowserInstanceId();
    }, signalrLogger),
    () => firstValueFrom(authentication.getToken()).catch(() => void 0),
    (url: string) =>
      `${url}?subscriptionId=${backendConfiguration.getSubscriptionId()}&companyId=${backendConfiguration.getCompanyId()}&browserInstanceId=${backendConfiguration.getBrowserInstanceId()}`
  );
}

export function initializeGridOptionsBuilderFactory(translateService: TranslateService, router: Router, gridSettingsService: HttpGridSettingsService): GridOptionsBuilderFactoryService {
  return new GridOptionsBuilderFactoryService(
    translateService,
    router,
    (identifier: GridIdentifier, columnState: Record<string, any>) => gridSettingsService.save({key: identifier, value: JSON.stringify(columnState)}),
    (identifier: GridIdentifier) => gridSettingsService.get(identifier)
  );
}

export function initializeMSALInstance(backendConfiguration: BackendConfiguration): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: backendConfiguration.getAuthenticationClientId(),
      authority: backendConfiguration.getAuthenticationAuthority(),
      redirectUri: backendConfiguration.getRedirectURL(),
      postLogoutRedirectUri: backendConfiguration.getRedirectURL(),
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        logLevel: environment.production ? LogLevel.Warning : LogLevel.Trace,
        piiLoggingEnabled: false
      }
    }
  });
}
