import {Component, Inject} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {isNil} from 'lodash-es';
import {DatepickerHeaderComponent} from '../datepicker-header/datepicker-header.component';
import {moment} from './../../../common/moment';

@Component({
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  public readonly datepickerHeader = DatepickerHeaderComponent;
  public selected: DateRange<Date> | Date;
  public minDate: Date;
  public startAtDate: Date;
  private pickedStartDate = false;
  private isRangePicker: boolean;

  private readonly dialogRef: MatDialogRef<DatePickerComponent>;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: {minDate: Date; date: Date; endDate: Date; isRangePicker: boolean},
    dialogRef: MatDialogRef<DatePickerComponent>
  ) {
    this.dialogRef = dialogRef;
    const {minDate, isRangePicker} = data;
    let {date, endDate} = data;

    this.minDate = minDate;
    this.isRangePicker = isNil(isRangePicker) ? false : isRangePicker;
    date = isNil(date) ? new Date() : date;
    const end = isNil(endDate) ? new Date() : endDate;
    endDate = !this.isRangePicker ? null : end;
    this.selected = this.isRangePicker ? new DateRange(date, endDate) : date;
    this.startAtDate = date;
  }

  public onSelectedChanges(event: Date): void {
    if (isNil(event) || !this.isRangePicker) {
      this.dialogRef.close(event);
    } else {
      this.selected = this.selected as DateRange<Date>;

      if (moment(event).isBefore(this.selected.start) || !this.pickedStartDate) {
        this.selected = new DateRange(moment(event).startOf('day').toDate(), null);
        this.pickedStartDate = true;
      } else {
        this.selected = new DateRange(this.selected.start, moment(event).endOf('day').toDate());
        this.dialogRef.close([this.selected.start, this.selected.end]);
      }
    }
  }
}
