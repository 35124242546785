<div class="invalid-form-position flex-row flex-start-center gap-2" [class.compact]="compact">
  <mat-icon class="warning-icon" [svgIcon]="compact ? 'solid-error-circle-rounded' : 'error'"></mat-icon>
  @if (compact) {
    <span *ngIf="compact">{{ message | bmsTranslate }}</span>
  } @else {
    <mat-error *ngIf="!compact" class="form-validation-error">
      {{ message | bmsTranslate }}
    </mat-error>
  }

  @if (link && !isDialog()) {
    <a *ngIf="link" class="url" [routerLink]="link">{{ linkTranslationKey | bmsTranslate }}</a>
  }
  @if (loadEntry && isDialog()) {
    <div (click)="navigate()" class="url">{{ linkTranslationKey | bmsTranslate }}</div>
  }
</div>
