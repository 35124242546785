import {Component, Inject, OnInit} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {intComparator} from '@application/helper/int-comparator';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {StringUtils} from '@application/helper/string-utils';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PaymentStatus} from '@domain/sales-order/payment-status';
import {PageUrls} from '@domain/textile-data/page-urls';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {SALES_ORDERS, SalesOrders} from '@infrastructure/http/sales-order/sales-orders';
import {OverviewListSalesOrder} from '@presentation/pages/texfab/sales-order/overview/overview-list-sales-order';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  BaseComponent,
  ColDefBuilderFactoryService,
  DialogBuilderFactoryService,
  DialogType,
  GridTagComponent,
  LinkIconRendererComponent,
  Priority,
  TagColor,
  TagSize,
  ToastService,
  TranslateService
} from '@vdw/angular-component-library';
import {CellClassParams, CellClickedEvent, ColDef, GridApi, ICellRendererParams, ITooltipParams} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './sales-order-overview-page.component.html',
  styleUrls: ['./sales-order-overview-page.component.scss']
})
export class SalesOrderOverviewPageComponent extends BaseComponent implements OnInit {
  public listOfSalesOrders: OverviewListSalesOrder[];

  public readonly textileDataType = TextileDataType;
  public readonly GRID_IDENTIFIER = GridIdentifier.SALES_ORDER_OVERVIEW;

  private pageUrls: PageUrls;
  private currentSubscription: Subscription;

  public constructor(
    @Inject(SALES_ORDERS) public salesOrders: SalesOrders,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.pageUrls = LastModifiedCardUtils.getPageUrls('salesOrder');
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.getSalesOrders();
  }

  public getColumnDefsForListOfSalesOrders(): ColDef[] {
    const colDefs = [
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('orderNumber')
        .withField('orderNumber', true)
        .withHeaderName('SALES_ORDERS.ORDER_NUMBER')
        .withComparator(intComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .build(),
      this.colDefBuilderFactory.getBuilder().withColId('name').withField('customer.name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory.getBuilder().withColIdAndField('desiredDate').withHeaderName('SALES_ORDERS.DESIRED_DATE').withDate().withDateMultiFilter().build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('priority')
        .withHeaderName('SALES_ORDERS.PRIORITY.PRIORITY')
        .withCellRenderer(GridTagComponent, {
          translationKey: 'SALES_ORDERS.PRIORITY.',
          size: TagSize.LARGE,
          colorGetter: {
            [Priority.LOW]: TagColor.PRIMARY,
            [Priority.MEDIUM]: TagColor.WARNING,
            [Priority.HIGH]: TagColor.ERROR
          }
        })
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`SALES_ORDERS.PRIORITY.${params.value}`).toUpperCase())
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('paymentStatus')
        .withHeaderName('SALES_ORDERS.PAYMENT_STATUS.PAYMENT_STATUS')
        .withCellRenderer(GridTagComponent, {
          colorGetter: {
            [PaymentStatus.PAID]: TagColor.SUCCESS,
            [PaymentStatus.OPEN]: TagColor.ERROR,
            [PaymentStatus.ADVANCE_PAID]: TagColor.PRIMARY
          },
          translationKey: 'SALES_ORDERS.PAYMENT_STATUS.',
          size: TagSize.LARGE
        })
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`SALES_ORDERS.PAYMENT_STATUS.${params.value}`).toUpperCase())
        .build()
    ];

    if (this.currentSubscription?.hasPermission(Permission.TEXFAB_ORDERBOOK)) {
      const addToOrderBookColDef = this.colDefBuilderFactory
        .getBuilder()
        .withColId('actions')
        .withHeaderName('GENERAL.ACTIONS.ACTIONS')
        .withCellRenderer((params: ICellRendererParams) => {
          const translationKey = (params.data as OverviewListSalesOrder).addedToOrderBook ? 'SALES_ORDERS.ADDED' : 'SALES_ORDERS.ADD_TO_ORDER_BOOK';
          return this.translate.instant(translationKey);
        })
        .withOnCellClicked((event: CellClickedEvent) => this.addToOrderBook(event))
        .withCellClass((params: CellClassParams) => ((params.data as OverviewListSalesOrder).addedToOrderBook ? [] : ['url']))
        .withoutFilter()
        .build();

      colDefs.push(addToOrderBookColDef);
    }

    return colDefs;
  }

  public exportGridForSalesOrders(gridApi: GridApi): void {
    gridApi.exportDataAsCsv({
      columnKeys: ['orderNumber', 'name', 'desiredDate', 'priority', 'paymentStatus']
    });
  }

  private getSalesOrders(): void {
    this.salesOrders
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((listOfSalesOrders: OverviewListSalesOrder[]) => (this.listOfSalesOrders = listOfSalesOrders));
  }

  private addToOrderBook(event: CellClickedEvent): void {
    if (!(event.data as OverviewListSalesOrder).addedToOrderBook) {
      this.salesOrders
        .copyToOrderbook(event.data.id)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: () => {
            this.toastService.showInfo({
              tapToDismiss: false,
              timeOut: 2000,
              message: this.translate.instant('SALES_ORDERS.ADDED')
            });
            this.getSalesOrders();
          },
          error: () => {
            this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
              titleText: this.translate.instant('SALES_ORDERS.ADD_TO_ORDER_BOOK'),
              messageText: this.translate.instant('SALES_ORDERS.ADD_TO_ORDER_BOOK_ERROR', {salesOrderName: event.data.orderNumber}),
              type: DialogType.INFORMATION
            });
          }
        });
    }
  }
}
