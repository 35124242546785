import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import {Point} from '../../../common/interfaces/point';
import {PlanningGroupData} from '../../planning-items/planning-item-base/planning-group-data';
import {DragDropData} from '../planning-drag-drop-data';

@Component({
  templateUrl: './planning-drag-indicator.component.html',
  styleUrls: ['./planning-drag-indicator.component.scss']
})
export class PlanningDragIndicatorComponent {
  @ViewChild('container')
  public set container(value: ElementRef<HTMLDivElement>) {
    if (this._container === value) {
      return;
    }
    this._container = value;
    this.setPosition(this.position);
  }

  public get container(): ElementRef<HTMLDivElement> {
    return this._container;
  }

  @ViewChild('newGroupHeader')
  public newGroupHeader: ElementRef<HTMLDivElement>;

  public templateContext = {
    $implicit: (reference: HTMLDivElement): void => {
      if (this.groupTimelabelRef !== reference) {
        this.groupTimelabelRef = reference;
      }
    }
  };

  private groupTimelabelRef: HTMLDivElement;

  public template: TemplateRef<any>;
  public width: number;
  public itemClass: string;
  public groupClass: string;

  public newGroupText: string;

  private isNestedItem: boolean;
  private hoveredOverPlanningGroup: boolean;

  private position: Point = {x: 0, y: 0};
  private _container: ElementRef<HTMLDivElement>;

  public init(data: DragDropData, newGroupText: string): void {
    this.newGroupText = newGroupText;
    this.width = data.indicatorWidthPx;
    this.template = data.draggedItem?.template;
    this.itemClass = data.draggedItem?.class;
    this.isNestedItem = data.sourceGroup && data.draggedItem !== data.sourceGroup;

    if (this.isNestedItem) {
      this.groupClass = data.sourceGroup?.class;
    }

    this.updateIndicator(data);
  }

  public updateIndicator(data: DragDropData): void {
    this.hoveredOverPlanningGroup = data.targetItem instanceof PlanningGroupData;
    this.setPosition(data.dragIndicatorPositionOnScreen);

    if (this.newGroupText != null && this.isNestedItem) {
      this.setHeight();
      this.setReactiveIndicatorStyling();
    }
  }

  private setPosition(position: Point): void {
    this.position = position;

    if (this.container != null) {
      this.container.nativeElement.style.left = `${this.position.x}px`;
      this.container.nativeElement.style.top = `${this.position.y}px`;
    }
  }

  private setHeight(): void {
    if (this.container == null) {
      return;
    }

    if (this.hoveredOverPlanningGroup) {
      this.container.nativeElement.classList.add('item-in-group');
    } else {
      this.container.nativeElement.classList.remove('item-in-group');
    }
  }

  private setReactiveIndicatorStyling(): void {
    if (this.newGroupHeader == null || this.container == null) {
      return;
    }

    if (!this.hoveredOverPlanningGroup) {
      this.container.nativeElement.classList.add('header-visible');
      this.newGroupHeader.nativeElement.classList.add('show-element');
    } else {
      this.container.nativeElement.classList.remove('header-visible');
      this.newGroupHeader.nativeElement.classList.remove('show-element');
    }
  }
}
