@if (canShowSingleIcon()) {
  <button [matTooltip]="buttons[0].title | bmsTranslate" matTooltipPosition="above" class="cell-icon-button" (click)="iconClicked($event, buttons[0])">
    <mat-icon [svgIcon]="buttons[0].icon" class="icon-12 flex-icon"></mat-icon>
  </button>
} @else {
  <button #hoverButton (mouseenter)="showOverlay()" (mouseleave)="onMouseLeave()" class="cell-icon-button more-vert">
    <mat-icon svgIcon="solid-more-vert" class="icon-16 flex-icon"></mat-icon>
  </button>
}

<ng-template #overlayTemplate class="menu-container">
  <div class="cell-icon-container flex-center" (mouseenter)="cancelClose()" (mouseleave)="closeOverlay()">
    @for (button of buttons; track $index) {
      <button [matTooltip]="button.title | bmsTranslate" matTooltipPosition="above" class="cell-icon-button flex-center" (click)="iconClicked($event, button)">
        <mat-icon [svgIcon]="button.icon" class="icon-12 flex-icon"></mat-icon>
      </button>
    }
  </div>
</ng-template>
