import {Inject, Injectable, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {ColDef, GridApi} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {CrudOverviewButtonConfig} from '../custom-components/crud-overview-data/interfaces/crud-overview-button-config.interface';
import {TranslateService} from '../translation/translate.service';
import {GridOptionsBuilder} from './grid-options-builder';

type SaveGridSettingsFunction = (identifier: any, columnState: Record<string, any>) => Observable<any>;
type GetGridSettingsFunction = (identifier: any) => Observable<unknown>;

@Injectable({providedIn: 'root'})
export class GridOptionsBuilderFactoryService {
  public constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    @Optional() @Inject('SaveGridSettingsFunction') private readonly saveGridSettings: SaveGridSettingsFunction,
    @Optional() @Inject('GetGridSettingsFunction') private readonly getGridSettings: GetGridSettingsFunction
  ) {}

  public getBuilder(columnDefs: ColDef[], gridIdentifier: string, rowData?: any, resizable: boolean = false): GridOptionsBuilder {
    return new GridOptionsBuilder(this.translateService, this.router, this.saveGridSettings, this.getGridSettings).getDefaultGridOptions(columnDefs, gridIdentifier, rowData, resizable);
  }

  public getCrudBuilder(columnDefs: ColDef[], gridIdentifier: string, rowData: any, rowActionButtons: CrudOverviewButtonConfig[], resizable: boolean = false): GridOptionsBuilder {
    return new GridOptionsBuilder(this.translateService, this.router, this.saveGridSettings, this.getGridSettings).getDefaultCrudGridOptions(
      columnDefs,
      gridIdentifier,
      rowData,
      rowActionButtons,
      resizable
    );
  }

  public getBuilderFromExistingGridApi(gridApi: GridApi): GridOptionsBuilder {
    return new GridOptionsBuilder(this.translateService, this.router, this.saveGridSettings, this.getGridSettings).fromExistingGridApi(gridApi);
  }
}
