import {Component, OnInit} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {Pattern} from '@domain/calendar/pattern';
import {HttpPatternsService} from '@infrastructure/http/calendar/pattern/http-patterns.service';
import {CrudOverviewDataBuilderFactoryService, CrudOverviewDataConfig, GridOptionsBuilder} from '@vdw/angular-component-library';
import {PatternOverviewService} from './pattern-overview.service';

@Component({
  templateUrl: './pattern-overview-page.component.html'
})
export class PatternOverviewPageComponent implements OnInit {
  public config: CrudOverviewDataConfig<Pattern>;

  public constructor(
    private readonly crudOverviewDataBuilderFactory: CrudOverviewDataBuilderFactoryService<Pattern>,
    private readonly patterns: HttpPatternsService,
    private readonly patternOverviewService: PatternOverviewService
  ) {}

  public ngOnInit(): void {
    const colDefs = this.patternOverviewService.getColumnDefs();

    this.config = this.crudOverviewDataBuilderFactory
      .getBuilder()
      .withRowData(this.patterns.getAll())
      .withGridOptions(colDefs, GridIdentifier.PLASTIC_PRODUCT_OVERVIEW, null, (builder: GridOptionsBuilder) => this.patternOverviewService.getDefaultGridOptionsBuilder(builder).build())
      .withEntityName(NavigationUtils.getNavigationData(NavigationId.PATTERN_MODULE).navigationKey)
      .build();
  }
}
