// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "f60c192d2a-dirty",
    "hash": "f60c192d2a",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "f60c192d2a-dirty",
    "semverString": null,
    "version": "5.40.0"
};
