<ng-template #groupTemplate let-register>
  <div class="group-container {{ data.class }}">
    <div class="group-header" [class.cursor-pointer]="data.clickable" [class.cursor-grab]="data.draggable">
      <vdw-ellipsis-label class="font-weight-semibold group-header-title micro" [class.dragged]="!!register" [text]="groupText" matTooltipPosition="above"> </vdw-ellipsis-label>
    </div>
    <div class="group-items">
      <div
        class="group-item {{ item.class }}"
        *ngFor="let item of data.items"
        [data]="item"
        vdwTimelinePosition
        [parentStartDate]="getStartDateForPositioning()"
        [parentEndDate]="getEndDateForPositioning()"
      >
        <div *ngTemplateOutlet="item.template"></div>
      </div>
    </div>
  </div>
</ng-template>
