import {AfterViewInit, Component, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateTime} from 'luxon';
import {debounceTime, map, startWith, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {DatepickerHeaderComponent} from '../datepicker-header/datepicker-header.component';

@Component({
  selector: 'vdw-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent extends BaseComponent implements AfterViewInit {
  public readonly DATE_PICKER_HEADER = DatepickerHeaderComponent;

  @Input()
  public standardRange = 4;

  public readonly dateSelectForm = new FormGroup({
    startDate: new FormControl(new Date()),
    endDate: new FormControl(DateTime.now().plus({days: this.standardRange}).toJSDate())
  });

  @Output()
  public readonly dateRangeChanged = this.dateSelectForm.valueChanges.pipe(
    startWith(undefined),
    takeUntil(this.unSubscribeOnViewDestroy),
    debounceTime(100),
    map(() => this.dateSelectForm.value)
  );

  @ViewChild('datePicker')
  public datePicker: MatDatepicker<Date>;

  public get startDate(): Date {
    return this.dateSelectForm.controls.startDate.value;
  }

  public get endDate(): Date {
    return this.dateSelectForm.controls.endDate.value;
  }

  public ngAfterViewInit(): void {
    this.datePicker.closedStream.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      const startDateForm = this.dateSelectForm.controls.startDate;
      const endDateForm = this.dateSelectForm.controls.endDate;
      if (AssertionUtils.isNullOrUndefined(endDateForm.value)) {
        endDateForm.setValue(startDateForm.value);
      }
    });
  }

  public goForwards(): void {
    this.moveRangeByDays(1);
  }

  public goBackwards(): void {
    this.moveRangeByDays(-1);
  }

  public clear(): void {
    this.dateSelectForm.controls.startDate.setValue(null);
    this.dateSelectForm.controls.endDate.setValue(null);
    this.datePicker.close();
  }

  public today(): void {
    this.dateSelectForm.controls.startDate.setValue(new Date());
    this.dateSelectForm.controls.endDate.setValue(new Date());
    this.datePicker.close();
  }

  private moveRangeByDays(amount: number): void {
    const startDateForm = this.dateSelectForm.controls.startDate;
    const endDateForm = this.dateSelectForm.controls.endDate;
    startDateForm.setValue(DateTime.fromJSDate(startDateForm.value).plus({days: amount}).toJSDate());
    endDateForm.setValue(DateTime.fromJSDate(endDateForm.value).plus({days: amount}).toJSDate());
  }
}
