import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {PatternOverviewPageComponent} from './overview/pattern-overview-page.component';

const shared = {parentId: NavigationId.PATTERN_MODULE};

export const patternRoutes = [
  AuthorizedRouteFactory.createPrototypeComponentRoute(PatternOverviewPageComponent, {route: '', requiredPermission: Permission.SHIFT_PATTERN_VIEW, reuse: true, id: NavigationId.PATTERN, ...shared})
];

export const patternRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(patternRoutes);
