import {Component, ComponentRef, Injector, OnInit, Optional, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {CrudObjectSelectionDialogComponent} from '../crud-overview-data/crud-object-selection-dialog/crud-object-selection-dialog.component';
import {NavigationHelperPrototypeService} from '../navigation-helper-prototype/navigation-helper-prototype.service';
import {ContentSwitcherComponentInfo} from './content-switcher-component-info.interface';

@Component({
  templateUrl: './content-switcher-dialog-prototype.component.html',
  styleUrls: ['./content-switcher-dialog-prototype.component.scss']
})
export class ContentSwitcherDialogPrototypeComponent extends BaseComponent implements OnInit {
  @ViewChild('contentSwitcherContainer', {read: ViewContainerRef, static: true}) public contentSwitcherContainer;

  protected injector: Injector;

  public get showHeader(): boolean {
    return (this.navigationHelper?.activeEntry?.componentInfo as ContentSwitcherComponentInfo)?.component !== CrudObjectSelectionDialogComponent;
  }

  public constructor(
    @Optional() private readonly dialog: MatDialogRef<any>,
    protected readonly navigationHelper: NavigationHelperPrototypeService
  ) {
    super();
  }

  public canShowBackButton(): boolean {
    return this.navigationHelper.navigationHistory.length >= 1;
  }

  public getContentSwitcherHeaderTitle(): string {
    if (!AssertionUtils.isNullOrUndefined((this.navigationHelper?.activeEntry?.componentInfo as ContentSwitcherComponentInfo)?.entityName)) {
      return (this.navigationHelper.activeEntry.componentInfo as ContentSwitcherComponentInfo).entityName;
    } else {
      return this.navigationHelper.getContentSwitcherHeaderTitle();
    }
  }

  public ngOnInit(): void {
    this.navigationHelper.setContentSwitcherDialog(this.dialog);

    this.dialog
      ?.beforeClosed()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.navigationHelper.resetContentSwitcher());

    this.injector = Injector.create({providers: [{provide: MatDialogRef, useValue: this.dialog}], parent: this.navigationHelper.getParentInjector()});

    this.navigationHelper.navigateForwardContentSwitcherComponent.subscribe((component: Type<any> | null) => {
      if (!AssertionUtils.isNullOrUndefined(component)) {
        if (!AssertionUtils.isNullOrUndefined(this.navigationHelper.navigationHistory[this.navigationHelper.navigationHistory.length - 1]?.componentRef)) {
          this.contentSwitcherContainer.detach();
        }

        this.navigationHelper.activeEntry.componentRef = this.contentSwitcherContainer.createComponent(component, {injector: this.injector});

        if (!AssertionUtils.isNullOrUndefined((this.navigationHelper.activeEntry.componentInfo as ContentSwitcherComponentInfo)?.componentInputs)) {
          Object.assign(this.navigationHelper.activeEntry.componentRef.instance, (this.navigationHelper.activeEntry.componentInfo as ContentSwitcherComponentInfo).componentInputs);
        }
      }
    });

    this.navigationHelper.navigateBackContentSwitcherComponent.subscribe((componentRef: ComponentRef<any>) => {
      this.contentSwitcherContainer.clear();

      this.contentSwitcherContainer.insert(componentRef.hostView);
    });
  }
}
