import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';
import {DateTimeFormat} from '../../common/datetime-format.enum';

@Pipe({name: 'DateTime'})
export class DateTimePipe implements PipeTransform {
  public transform(value: Date | DateTime, format: DateTimeFormat | string): string {
    let dateTime: DateTime;

    if (value === null || value === undefined) {
      return null;
    }
    if (value instanceof Date) {
      dateTime = DateTime.fromJSDate(value);
    } else if (value instanceof DateTime) {
      dateTime = value;
    } else {
      throw new Error('Unsupported value type');
    }
    if (typeof format === 'string') {
      format = DateTimeFormat[format as keyof typeof DateTimeFormat];
    }
    switch (format) {
      case DateTimeFormat.TIME_SIMPLE:
        return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
      case DateTimeFormat.TIME_WITH_SECONDS:
        return dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS);
      case DateTimeFormat.DATE_SHORT:
        return dateTime.toLocaleString(DateTime.DATE_SHORT);
      case DateTimeFormat.DATE_HUGE:
        return dateTime.toLocaleString(DateTime.DATE_HUGE);
      case DateTimeFormat.DAY_WEEKDAY:
        return dateTime.toFormat('ccc c');
      case DateTimeFormat.DATETIME_SHORT:
        return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
      case DateTimeFormat.DATETIME_SHORT_WITH_SECONDS:
        return dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      case DateTimeFormat.DATETIME_MED_WITH_WEEKDAY:
        return dateTime.toLocaleString({weekday: 'long', year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric'});
      case DateTimeFormat.MONTH_YEAR:
        return dateTime.toLocaleString({month: 'long', year: 'numeric'});
      case DateTimeFormat.DAY_MONTH_SHORT_TIME_WITH_SECONDS:
        return dateTime.toLocaleString({day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'});
      default:
        throw new Error('Unsupported or missing DateTime format');
    }
  }
}
