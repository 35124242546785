import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {interval, map, Observable, startWith} from 'rxjs';
import {DateTimeFormat} from '../../common/datetime-format.enum';
import {DateTimePipe} from '../date-time-pipe/date-time.pipe';

@Pipe({
  name: 'minutesFromNow'
})
export class MinutesFromNowPipe implements PipeTransform {
  public constructor(private readonly dateTimePipe: DateTimePipe) {}
  public transform(date: Date | string): Observable<string> {
    return interval(1000).pipe(
      startWith(0),
      map(() => {
        const dateToTransform = typeof date === 'string' ? new Date(date) : date;

        if (dateToTransform) {
          if (moment(dateToTransform).isAfter(moment().clone().subtract(1, 'hours'))) {
            return moment(dateToTransform).fromNow();
          } else {
            return this.dateTimePipe.transform(dateToTransform, DateTimeFormat.DAY_MONTH_SHORT_TIME_WITH_SECONDS);
          }
        }
        return '';
      })
    );
  }
}
