<div class="full-width full-height bms-theme flex-column p-24">
  @if (showHeader) {
    <div class="header">
      <button
        mat-icon-button
        class="mat-icon-action-button-lg-with-border back-button"
        (click)="contentSwitcher.navigateBack()"
        [matTooltip]="'back'"
        matTooltipPosition="above"
        matTooltipClass="bms-theme"
      >
        <mat-icon class="back-icon" svgIcon="solid-arrow-back"></mat-icon>
      </button>
      <vdw-ellipsis-label class="font-weight-semibold header-title" [tooltipDisabled]="true" [text]="headerText + createdObject"></vdw-ellipsis-label>
    </div>
  }

  <div class="full-width full-height" [class.component-container]="showHeader">
    <ng-container *ngComponentOutlet="component; inputs: componentInputs; injector: injector"></ng-container>
  </div>
</div>
