import {StringUtils} from '../utils/string-utils';
import {MessageType} from './message-type.enum';

export class Message {
  private _title: string;
  private _description: string;
  private _type: MessageType;
  private _timestamp: Date;

  public constructor(title: string, description: string, type: MessageType, timestamp: Date) {
    this._title = title;
    this._description = description;
    this._type = type;
    this._timestamp = timestamp;
  }

  public get title(): string {
    return this._title;
  }

  public get description(): string {
    return this._description;
  }

  public get type(): MessageType {
    return this._type;
  }

  public get timestamp(): Date {
    return this._timestamp;
  }

  public static fromJSON(message: any): Message {
    return new Message(message.title, message.description, MessageType.getMessageTypeByLabel(message.type), StringUtils.toDate(message.timestamp));
  }

  public static createErrorMessageWithDescription(description: string): Message {
    return this.createMessageWithTypeAndDescription(MessageType.ERROR, description);
  }

  public static createMessageWithTypeAndDescription(type: MessageType, description: string): Message {
    return new Message(null, description, type, null);
  }
}
