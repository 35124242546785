<vdw-tabs-and-actions-header class="editor-header-tabs-and-actions" [menuItems]="menuItems" [selectedMenuItem]="selectedMenuItem" (selectedMenuItemChange)="selectedMenuItemChange.emit($event)">
  @if (canShowExtraActions) {
    <vdw-settings-icon-button [showCustomSettingsButton]="canShowSettings" (customSettingsButtonClicked)="settingsEvent.emit()">
      @if (canShowDuplicate && !isCreateNewPage) {
        <button mat-button class="menu-item-button" (click)="duplicateEvent.emit()">
          <mat-icon svgIcon="solid-content-copy" />{{ 'ANGULAR_COMPONENT_LIBRARY.EDITOR_HEADER.DUPLICATE' | bmsTranslate }}
        </button>
      }
      @if (canShowDelete && !isCreateNewPage) {
        <button mat-button class="menu-item-button" (click)="deleteEvent.emit()"><mat-icon svgIcon="solid-delete" />{{ 'ANGULAR_COMPONENT_LIBRARY.EDITOR_HEADER.DELETE' | bmsTranslate }}</button>
      }
      @if (!isCreateNewPage) {
        @for (extraAction of customExtraActions; track $index) {
          <button mat-button class="menu-item-button" [disabled]="extraAction.disabled" (click)="extraAction.onClick($event)"><mat-icon [svgIcon]="extraAction.icon" />{{ extraAction.label }}</button>
        }
      }
    </vdw-settings-icon-button>
  }

  <button mat-button *ngIf="canShowCancel" (click)="cancelEvent.emit()">{{ 'ANGULAR_COMPONENT_LIBRARY.EDITOR_HEADER.CANCEL' | bmsTranslate }}</button>

  <vdw-split-button
    *ngIf="canShowSplitButton(); else singleAction"
    [label]="getSaveButtonTranslationKey() | bmsTranslate: {action: actionText | bmsTranslate}"
    [inProgress]="inProgress"
    [disabled]="disableSaveButton"
    (buttonClicked)="save(defaultSaveType)"
  >
    <button *ngIf="canShowSaveMenuButton()" mat-menu-item (click)="save(SAVE)">{{ actionText | bmsTranslate }}</button>
    <button *ngIf="canShowSaveAndCloseMenuButton()" mat-menu-item (click)="save(SAVE_AND_CLOSE)">
      {{ 'ANGULAR_COMPONENT_LIBRARY.EDITOR_HEADER.ACTION_AND_CLOSE' | bmsTranslate: {action: actionText | bmsTranslate} }}
    </button>
    <button *ngIf="canShowSaveAndCreateNewMenuButton()" mat-menu-item (click)="save(SAVE_AND_CREATE_NEW)">
      {{ 'ANGULAR_COMPONENT_LIBRARY.EDITOR_HEADER.ACTION_AND_CREATE_NEW' | bmsTranslate: {action: actionText | bmsTranslate} }}
    </button>
  </vdw-split-button>
  <ng-template #singleAction>
    <button *ngIf="canShowSave" mat-flat-button (click)="save(defaultSaveType)">{{ getSaveButtonTranslationKey() | bmsTranslate: {action: actionText | bmsTranslate} }}</button>
  </ng-template>
</vdw-tabs-and-actions-header>
