import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {IdNameUsed} from '@domain/id-name-used';
import {HttpRawMaterialsService} from '@infrastructure/http/raw-materials/http-raw-materials.service';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  ContentSwitcherDialogService,
  DialogBuilderFactoryService,
  DialogType,
  FormValidationHelper,
  PrototypeRouteUtils,
  SaveType,
  TabsMenuItem,
  TranslateService
} from '@vdw/angular-component-library';
import {finalize, Observable, takeUntil} from 'rxjs';
import {MaterialSelectionService} from '../material-selection.service';
import {AddRawMaterialNavigationData} from './add-raw-material-navigation.data';
import {RawMaterialForm} from './add-raw-material.form';

@Component({
  selector: 'app-add-raw-material',
  templateUrl: './add-raw-material.component.html'
})
export class AddRawMaterialComponent extends BaseComponent implements OnInit {
  public addRawMaterialForm: RawMaterialForm;
  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_RAW_MATERIAL;
  public selectedMenuItem: TabsMenuItem = {value: 0, translationKey: 'GENERAL.GENERAL'};
  public readonly RAW_MATERIAL_TRANSLATION_KEY = 'TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL';
  public rawMaterialId: number;
  public isRawMaterialUsed = false;

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly rawMaterialService: HttpRawMaterialsService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly contentSwitcher: ContentSwitcherDialogService<AddRawMaterialNavigationData>,
    private readonly materialSelectionService: MaterialSelectionService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setFormFields();
    this.getRawMaterialDetails();
  }

  public isEditingRawMaterial(): boolean {
    return PrototypeRouteUtils.isEditPath(this.contentSwitcher.activeEntry?.routeData?.route);
  }

  public cancel(): void {
    this.contentSwitcher.navigateBack();
  }

  public getLoadLink(): void {
    // content switcher logic not implemented to handle this logic. once logic is done this comment will be removed
    // Task for this: https://vandewiele.atlassian.net/browse/BMSMC-7615
    // return ErrorHandlers.getLoadLink(RouteUtils.paths.utilities.rawMaterial.editStockLocation.absolutePath, this.addRawMaterialForm.controls.name);
  }

  public save(saveType: SaveType): void {
    const isValid = new FormValidationHelper().checkForm(this.addRawMaterialForm, this.document);
    if (isValid) {
      this.saving = true;
      const rawMaterialToSave = this.getCurrentRawMaterial();

      const request: Observable<void | number> = this.isEditingRawMaterial() ? this.rawMaterialService.update(rawMaterialToSave) : this.rawMaterialService.save(rawMaterialToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) => {
          if (saveType === SaveType.SAVE_AND_CLOSE) {
            rawMaterialToSave.id = id;
            this.materialSelectionService.setSelected(rawMaterialToSave);

            this.contentSwitcher.navigateBack();
          } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
            this.resetFormFields();
          }
        },
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError(this.isEditingRawMaterial() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  private getRawMaterialDetails(): void {
    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.getActiveEntryData()?.rawMaterial)) {
      this.selectedMenuItem = this.contentSwitcher.getActiveEntryData().activeTab;
      this.rawMaterialId = this.contentSwitcher.getActiveEntryData().rawMaterial.id;
      this.setFormValues(this.contentSwitcher.getActiveEntryData().rawMaterial);
      return;
    }

    const id = PrototypeRouteUtils.isAddPath(this.contentSwitcher.activeEntry?.routeData?.route) ? null : this.contentSwitcher.getActiveEntryData()?.routeId ?? null;

    if (!AssertionUtils.isNullOrUndefined(id)) {
      this.rawMaterialService
        .getById(Number(id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((rawMaterial: IdNameUsed) => {
          this.rawMaterialId = rawMaterial.id;
          this.isRawMaterialUsed = rawMaterial.used;
          this.setFormValues(rawMaterial);
          if (this.isRawMaterialUsed) {
            this.disableNameValue();
          }
        });
    }
  }

  private disableNameValue(): void {
    this.addRawMaterialForm.get('name').disable();
  }

  private setFormValues(rawMaterial: IdNameUsed): void {
    this.addRawMaterialForm.reset({
      name: rawMaterial.name
    });
  }

  private getCurrentRawMaterial(): IdNameUsed {
    return new IdNameUsed(this.isEditingRawMaterial() ? this.rawMaterialId : null, this.addRawMaterialForm.value.name, false);
  }

  private setFormFields(): void {
    this.addRawMaterialForm = this.formBuilder.group({
      name: this.formBuilder.control('', Validators.required, AsyncUniqueValidator.createValidator(this.rawMaterialService, null))
    });
  }

  private resetFormFields(): void {
    this.addRawMaterialForm.reset();
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {object: this.translate.instant(this.RAW_MATERIAL_TRANSLATION_KEY)}),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }
}
