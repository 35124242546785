import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {CrudOverviewButtonConfig} from '../../custom-components/crud-overview-data/interfaces/crud-overview-button-config.interface';
import {CellButtonParams} from './cell-button-params.interface';

@Component({
  templateUrl: './cell-button.component.html',
  styleUrls: ['./cell-button.component.scss']
})
export class CellButtonComponent extends BaseComponent implements ICellRendererAngularComp {
  @ViewChild('hoverButton', {read: ElementRef}) public hoverButton: ElementRef;
  @ViewChild('overlayTemplate') public overlayTemplate: TemplateRef<any>;

  public buttons: CrudOverviewButtonConfig[];
  public menuWidth = 0;
  public offsetXWidthPerButton = -8;
  private overlayRef: OverlayRef;
  private isOverlayOpen = false;
  private params: CellButtonParams;
  private closeOverlayTimeout: any;

  public constructor(
    private overlay: Overlay,
    private vcr: ViewContainerRef
  ) {
    super();
  }

  public agInit(params: CellButtonParams): void {
    this.params = params;
    this.buttons = params.buttons;
  }

  public showOverlay(): void {
    if (AssertionUtils.isNullOrUndefined(this.overlayRef)) {
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.hoverButton)
        .withPositions([{originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top', offsetX: 6}]);

      this.overlayRef = this.overlay.create({positionStrategy, hasBackdrop: false});
      const portal = new TemplatePortal(this.overlayTemplate, this.vcr);
      this.overlayRef.attach(portal);

      this.isOverlayOpen = true;
    }
  }

  public closeOverlay(): void {
    if (this.isOverlayOpen) {
      this.overlayRef?.detach();
      this.overlayRef = undefined;
      this.isOverlayOpen = false;
    }
  }

  public onMouseLeave(): void {
    this.closeOverlayTimeout = setTimeout(() => {
      this.closeOverlay();
    });
  }

  public cancelClose(): void {
    if (this.closeOverlayTimeout) {
      clearTimeout(this.closeOverlayTimeout);
      this.closeOverlayTimeout = null;
    }
  }

  public refresh(params: any): boolean {
    return false;
  }

  public stopClickEvent(event: PointerEvent): void {
    event.stopPropagation();
  }

  public canShowSingleIcon(): boolean {
    return this.buttons.length === 1;
  }

  public iconClicked(event: PointerEvent, button: CrudOverviewButtonConfig): void {
    event.stopPropagation();
    button.onClick({gridApi: this.params.api, rowNode: this.params.node});
  }
}
