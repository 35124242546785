import {Injectable} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {includes, map} from 'lodash-es';
import {Settings} from 'luxon';
import {moment} from '../../common/moment';
import {EnumUtils} from '../../common/utils/enum-utils';
import {LocalStorageService} from '../../custom-services/local-storage.service/local-storage.service';
import {TranslateService} from '../translate.service';
import {Language} from './language.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public static readonly AVAILABLE_LANGUAGES: string[] = EnumUtils.getEnumNames(Language);
  public static readonly LOCAL_STORAGE_KEY = 'language';
  public static readonly LOCAL_STORAGE_KEY_REGION = 'region';
  public static readonly DEFAULT_LANGUAGE: string = EnumUtils.getKeyFromValue(Language, Language.en);
  private static readonly EXPERIMENTAL_LANGUAGES = [EnumUtils.getKeyFromValue(Language, Language.tr), EnumUtils.getKeyFromValue(Language, Language.zh)];
  private static readonly REGIONS = [
    'ar-SA',
    'bn-BD',
    'bn-IN',
    'cs-CZ',
    'da-DK',
    'de-AT',
    'de-CH',
    'de-DE',
    'el-GR',
    'en-AU',
    'en-CA',
    'en-GB',
    'en-IE',
    'en-IN',
    'en-NZ',
    'en-US',
    'en-ZA',
    'es-AR',
    'es-CL',
    'es-CO',
    'es-ES',
    'es-MX',
    'es-US',
    'fi-FI',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-FR',
    'he-IL',
    'hi-IN',
    'hu-HU',
    'id-ID',
    'it-CH',
    'it-IT',
    'ja-JP',
    'ko-KR',
    'nl-BE',
    'nl-NL',
    'no-NO',
    'pl-PL',
    'pt-BR',
    'pt-PT',
    'ro-RO',
    'ru-RU',
    'sk-SK',
    'sv-SE',
    'ta-IN',
    'ta-LK',
    'th-TH',
    'tr-TR',
    'zh-CN',
    'zh-HK',
    'zh-TW'
  ];

  public constructor(
    private readonly translate: TranslateService,
    private readonly localStorage: LocalStorageService,
    private readonly dateAdapter: DateAdapter<Date>
  ) {}

  public initialize(): Promise<void> {
    let storedLanguage: string = this.localStorage.get(LanguageService.LOCAL_STORAGE_KEY);
    let storedRegion: string = this.localStorage.get(LanguageService.LOCAL_STORAGE_KEY_REGION);
    if (!storedLanguage) {
      storedLanguage = LanguageService.DEFAULT_LANGUAGE;
      this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, storedLanguage);
    }
    if (!storedRegion) {
      storedRegion = 'en-GB';
      this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY_REGION, storedRegion);
    }

    moment.locale(storedLanguage);
    Settings.defaultLocale = storedRegion;
    this.dateAdapter.setLocale(storedRegion);

    return this.setLanguage(storedLanguage);
  }

  public changeLanguage(language: string): Promise<void> {
    this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, language);
    moment.locale(language);
    return this.setLanguage(language);
  }

  public changeRegion(region: string): Promise<void> {
    this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY_REGION, region);
    Settings.defaultLocale = region;
    this.dateAdapter.setLocale(region);
    return Promise.resolve();
  }

  public getActiveLanguage(): string {
    return this.translate.getLocale().language;
  }

  public getActiveRegion(): string {
    return Settings.defaultLocale;
  }

  public getAvailableLanguages(): {key: string; label: string}[] {
    return map(LanguageService.AVAILABLE_LANGUAGES, (availableLanguageKey: string) => {
      return {
        key: availableLanguageKey,
        label: Language[availableLanguageKey]
      };
    });
  }

  public getAvailableRegions(): {key: string; displayName: string}[] {
    let languageNames = new Intl.DisplayNames(['en'], {type: 'language', languageDisplay: 'standard'});
    return LanguageService.REGIONS.map((region: string) => {
      return {key: region, displayName: languageNames.of(region)};
    }).sort((a: {key: string; displayName: string}, b: {key: string; displayName: string}) => a.displayName.localeCompare(b.displayName));
  }

  public isExperimentalLanguage(language: string): boolean {
    return includes(LanguageService.EXPERIMENTAL_LANGUAGES, language);
  }

  private setLanguage(language: string): Promise<void> {
    return this.translate.setLocale({language});
  }
}
