import {ComponentRef, Injectable, Injector, Type} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ContentEntry} from '../content-switcher-prototype/content-entry.interface';
import {ContentSwitcherComponentInfo} from '../content-switcher-prototype/content-switcher-component-info.interface';
import {ContentSwitcherDialogPrototypeService} from '../content-switcher-prototype/content-switcher-dialog-prototype.service';
import {RoutedComponentInfo} from '../content-switcher-prototype/routed-component-info.interface';

@Injectable({providedIn: 'root'})
export class NavigationHelperPrototypeService<T = unknown> {
  public activeEntry: ContentEntry;
  public navigationHistory: ContentEntry[] = [];

  public navigateForwardContentSwitcherComponent: BehaviorSubject<Type<any> | null> = new BehaviorSubject<Type<any> | null>(null);
  public navigateBackContentSwitcherComponent: Subject<ComponentRef<any>> = new Subject();

  public constructor(
    private readonly router: Router,
    private readonly contentSwitcher: ContentSwitcherDialogPrototypeService
  ) {}

  public getActiveComponentData(): T {
    return this.activeEntry ? this.activeEntry.componentInfo.data : null;
  }

  public setContentSwitcherDialog(dialog: MatDialogRef<any>): void {
    this.contentSwitcher.dialog = dialog;
  }

  public getContentSwitcherHeaderTitle(): string {
    return !AssertionUtils.isNullOrUndefined(this.contentSwitcher?.headerTitle) ? this.contentSwitcher.headerTitle : '';
  }

  public getParentInjector(): Injector {
    return this.contentSwitcher.parentInjector;
  }

  public resetContentSwitcher(): void {
    this.contentSwitcher.dialog = null;
    // Start from the last item in the navigation history and remove until all entry's until one of the entry's does not use the content-switcher
    for (let i = this.navigationHistory.length - 1; i >= 0; i--) {
      if (!this.navigationHistory[i].contentSwitcherEntry) {
        break; // Stop removing once contentSwitcherEntry is false
      }
      this.navigationHistory.splice(i, 1); // Remove the item at index i
    }
  }

  public navigateBack(data?: any): void {
    const previous = this.navigationHistory.pop();
    this.activeEntry.navigateBackData.next(data);
    this.activeEntry = previous;

    if (!AssertionUtils.isNullOrUndefined(previous)) {
      // If the previous route was in the content switcher, switch to the new component reference
      if (previous?.contentSwitcherEntry) {
        this.navigateBackContentSwitcherComponent.next(this.activeEntry.componentRef);
      } else {
        this.closeContentSwitcherDialog(data);
      }
    } else if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog)) {
      this.closeContentSwitcherDialog(data);
    }
  }

  public navigateForward<Q>(componentInfo: ContentSwitcherComponentInfo | RoutedComponentInfo): Observable<Q> {
    if (!AssertionUtils.isNullOrUndefined(this.activeEntry)) {
      this.navigationHistory.push(this.activeEntry);
    }

    this.activeEntry = {componentInfo, componentRef: null, navigateBackData: new Subject<Q>()} as ContentEntry;

    // If navigateInDialog is false && no dialog is open, we will be using standard routing
    if (!this.instanceOfContentSwitcherComponentInfo(componentInfo) && AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog)) {
      this.router.navigateByUrl(componentInfo.routeData.route);
    }

    // If navigateInDialog is true or a content switcher dialog is already open, we will be using the content-switcher dialog
    if (this.instanceOfContentSwitcherComponentInfo(componentInfo) || !AssertionUtils.isNullOrUndefined(this.contentSwitcher?.dialog)) {
      this.activeEntry.contentSwitcherEntry = true;

      // If the content-switcher dialog is not yet open, we should open the content switcher
      if (AssertionUtils.isNullOrUndefined(this.contentSwitcher?.dialog)) {
        this.contentSwitcher.openContentSwitcherDialog((componentInfo as ContentSwitcherComponentInfo).entityName);
      }

      this.navigateForwardContentSwitcherComponent.next((this.activeEntry.componentInfo as ContentSwitcherComponentInfo).component);
    }

    return this.activeEntry.navigateBackData.asObservable();
  }

  private instanceOfContentSwitcherComponentInfo(object: ContentSwitcherComponentInfo | RoutedComponentInfo): object is ContentSwitcherComponentInfo {
    return 'component' in object;
  }

  private closeContentSwitcherDialog(data: any): void {
    this.contentSwitcher.dialog.close(data);
  }
}
