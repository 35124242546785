import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {PlanningFilter} from '@domain/planning-prototype/custom-settings/planning-filter';
import {PlanningForecast} from '@domain/planning-prototype/planning-forecast';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {PlanningItemFactory} from '@domain/planning-prototype/planning-item-factory';
import {PlanningItemForecast} from '@domain/planning-prototype/planning-item-forecast';
import {PlanningLine} from '@domain/planning-prototype/planning-line';
import {FixedScheduleToPlan} from '@domain/planning-prototype/to-plan/fixed-schedule-to-plan';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {AssertionUtils} from '@vdw/angular-component-library';
import {UUID} from 'crypto';
import {DateTime} from 'luxon';
import {Observable, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpPlanningPrototypeService {
  private readonly HISTORY_REQUEST_FORMAT = 'yyyy-MM-dd';

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getForecast(equipmentGroupIds?: number[]): Observable<PlanningForecast> {
    let queryParams = new HttpParams();
    if (!AssertionUtils.isEmpty(equipmentGroupIds)) {
      for (const id of equipmentGroupIds) {
        queryParams = queryParams.append('equipmentGroupIds', id);
      }
    }

    return this.httpClient.get(`${this.backendConfiguration.getPlanningEndpoint()}planning/forecast`, {params: queryParams}).pipe(map(PlanningForecast.fromJSON));
  }

  public getForecastForDraft(planningLines: PlanningLine[]): Observable<PlanningItemForecast[]> {
    return this.httpClient
      .post(
        `${this.backendConfiguration.getPlanningEndpoint()}planning/forecast`,
        planningLines.map((planningLine: PlanningLine) => planningLine.toJSON())
      )
      .pipe(map((json: any[]) => json.map(PlanningItemForecast.fromJSON)));
  }

  public patchPlanningLines(planningLines: PlanningLine[]): Observable<{[draftId: UUID]: number}> {
    return this.httpClient.post<{[draftId: UUID]: number}>(`${this.backendConfiguration.getPlanningEndpoint()}planning`, {
      planningLines: planningLines.map((planningLine: PlanningLine) => planningLine.toJSON(true))
    });
  }

  public getProductionOrdersToPlan(equipmentGroupIds?: number[]): Observable<ProductionOrderToPlan[]> {
    let queryParams = new HttpParams();
    if (!AssertionUtils.isEmpty(equipmentGroupIds)) {
      for (const id of equipmentGroupIds) {
        queryParams = queryParams.append('equipmentGroupIds', id);
      }
    }

    return this.httpClient
      .get(`${this.backendConfiguration.getPlanningEndpoint()}production-orders/to-plan`, {params: queryParams})
      .pipe(map((json: any[]) => json.map(ProductionOrderToPlan.fromJSON)));
  }

  public getFixedSchedulesToPlan(): Observable<FixedScheduleToPlan[]> {
    return this.httpClient.get(`${this.backendConfiguration.getPlanningEndpoint()}fixed-schedules/to-plan`).pipe(map((json: any[]) => json.map(FixedScheduleToPlan.fromJSON)));
  }

  public getPlanningHistory(from: Date, to: Date, equipmentGroupIds?: number[]): Observable<{[id: number]: PlanningItem[]}> {
    let queryParams = new HttpParams().set('from', DateTime.fromJSDate(from).toFormat(this.HISTORY_REQUEST_FORMAT)).set('to', DateTime.fromJSDate(to).toFormat(this.HISTORY_REQUEST_FORMAT));

    if (!AssertionUtils.isEmpty(equipmentGroupIds)) {
      for (const id of equipmentGroupIds) {
        queryParams = queryParams.append('equipmentGroupIds', id);
      }
    }

    return this.httpClient.get(`${this.backendConfiguration.getPlanningEndpoint()}planning/history`, {params: queryParams}).pipe(
      map((json: {[id: number]: any[]}) =>
        Object.keys(json).reduce((result: {[id: number]: PlanningItem[]}, key: string) => {
          result[key] = json[key].map(PlanningItemFactory.fromJSON);
          return result;
        }, {})
      )
    );
  }

  public getFilter(): Observable<PlanningFilter> {
    return this.httpClient.get(`${this.backendConfiguration.getPlanningEndpoint()}custom-settings/filter`).pipe(map(PlanningFilter.fromJSON));
  }

  public setFilter(filter: PlanningFilter): Observable<void> {
    return this.httpClient.post(`${this.backendConfiguration.getPlanningEndpoint()}custom-settings/filter`, filter.toJSON()).pipe(map(() => undefined));
  }

  public getCreelMapForRun(runId: number): Observable<CreelMap[]> {
    return this.httpClient.get<CreelMap[]>(`${this.backendConfiguration.getPlanningEndpoint()}runs/${runId}/creel-mapping`);
  }
}
