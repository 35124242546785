import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgModule} from '@angular/core';
import {BreakPoint, FlexLayoutModule, MediaMarshaller} from '@angular/flex-layout';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MAT_CARD_CONFIG, MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {filter, isEmpty, noop} from 'lodash-es';

@NgModule({
  imports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatSliderModule,
    DragDropModule,
    MatDatepickerModule,
    MatRippleModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatSnackBarModule
  ],
  exports: [
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatSliderModule,
    DragDropModule,
    MatDatepickerModule,
    MatRippleModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'outlined'
      }
    }
  ]
})
export class MaterialModule {
  private lastActivatedBreakpoints: BreakPoint[];

  public constructor(mediaMarshaller: MediaMarshaller) {
    // @ts-ignore
    mediaMarshaller.subject.subscribe(() => {
      // @ts-ignore
      if (isEmpty(filter(mediaMarshaller.activatedBreakpoints, {alias: 'print'}))) {
        // @ts-ignore
        this.lastActivatedBreakpoints = [...mediaMarshaller.activatedBreakpoints];
      } else {
        // @ts-ignore
        mediaMarshaller.activatedBreakpoints = [...this.lastActivatedBreakpoints];
        // @ts-ignore
        mediaMarshaller.hook.collectActivations = noop;
        // @ts-ignore
        mediaMarshaller.hook.deactivations = [...this.lastActivatedBreakpoints];
      }
    });
  }
}
