<button type="button" mat-icon-button [class.z-index-4]="showSettingsDialog()" [class.open]="showSettingsDialog()" tabindex="-1" (click)="toggleSettingsDialog()">
  <mat-icon class="icon" svgIcon="solid-more-vert" />
</button>

@if (showSettingsDialog()) {
  <div class="invisible-overlay" (click)="toggleSettingsDialog()"></div>
  <div class="settings-dialog z-index-4" [class.right]="alignRight">
    <section>
      <ng-content />
    </section>
    @if (showCustomSettingsButton) {
      <section>
        <button mat-stroked-button (click)="customSettingsButtonClicked.emit(); toggleSettingsDialog()">{{ 'SETTINGS.MENU_NAME' | bmsTranslate }}</button>
      </section>
    }
  </div>
}
