import {HttpClient} from '@angular/common/http';
import {CustomSettings} from '@domain/custom-settings/custom-settings';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {map, Observable} from 'rxjs';

export class CustomSettingsRepository<TCustomSettings extends CustomSettings, TEntity> {
  public constructor(
    protected readonly httpClient: HttpClient,
    protected readonly baseUrl: string
  ) {}

  public get(customSettingsClass: typeof CustomSettings = CustomSettings): Observable<TCustomSettings> {
    return this.httpClient.get(this.baseUrl).pipe(map((json: any) => customSettingsClass.fromJSON(json) as TCustomSettings));
  }

  public save(customSettings: TCustomSettings): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl, customSettings.toJSON());
  }

  public getNameGenerationProperties(): Observable<NameGeneratorProperty[]> {
    return this.httpClient.get(`${this.baseUrl}/name-generation-properties`).pipe(map((json: any[]) => json.map((jsonElement: any) => NameGeneratorProperty.fromJSON(jsonElement))));
  }

  public generateName(entity: TEntity): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl}/generate-name`, (entity as any)?.toJSON() ?? null);
  }
}
