import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CustomSettings} from '@domain/custom-settings/custom-settings';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGenerator} from '@domain/custom-settings/name-generator';
import {HttpPlasticProductsCustomSettingsService} from '@infrastructure/http/plastic-product/http-plastic-products-custom-settings.service';
import {BaseComponent, NavigationHelperPrototypeService, TabsMenuItem, ToastService, TranslateService} from '@vdw/angular-component-library';
import {forkJoin, takeUntil} from 'rxjs';

@Component({
  templateUrl: './plastic-product-custom-settings.component.html'
})
export class PlasticProductCustomSettingsComponent extends BaseComponent implements OnInit {
  protected nameGenerationProperties: NameGeneratorProperty[] = [];
  protected settingsForm = new FormGroup({nameGenerator: new FormControl<NameGenerator>(null)});
  protected generalTab: TabsMenuItem = {
    value: 'generalTab',
    translationKey: 'GENERAL.GENERAL',
    form: this.settingsForm
  };

  public constructor(
    private readonly plasticProductCustomSettings: HttpPlasticProductsCustomSettingsService,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService,
    private readonly navigationHelper: NavigationHelperPrototypeService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getCustomSettings();
  }

  protected navigateBack(): void {
    this.navigationHelper.navigateBack();
  }

  protected save(): void {
    this.plasticProductCustomSettings
      .save(new CustomSettings(undefined, this.settingsForm.value.nameGenerator))
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.navigateBack();
        this.toastService.showInfo({
          tapToDismiss: false,
          timeOut: 2000,
          message: this.translate.instant('GENERAL.ACTIONS.UPDATED_OBJECT_WITHOUT_NAME', {object: 'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS'})
        });
      });
  }

  private getCustomSettings(): void {
    forkJoin([this.plasticProductCustomSettings.get(), this.plasticProductCustomSettings.getNameGenerationProperties()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([customSettings, nameGenerationProperties]: [CustomSettings, NameGeneratorProperty[]]) => {
        this.settingsForm.controls.nameGenerator.patchValue(customSettings.nameGenerator);
        this.nameGenerationProperties = nameGenerationProperties;
      });
  }
}
