import {Injectable} from '@angular/core';
import {Pattern} from '@domain/calendar/pattern';
import {MultiDeleteResponse} from '@domain/textile-data/multi-delete-reponse';
import {Repository} from '@infrastructure/http/repository';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpPatternsService implements Repository<Pattern, Pattern> {
  public getAll(): Observable<Pattern[]> {
    throw new Error('Method not implemented.');
  }

  public delete(id: number | string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  public getById(id: number | string): Observable<Pattern> {
    throw new Error('Method not implemented.');
  }

  public save(item: Pattern): Observable<number> {
    throw new Error('Method not implemented.');
  }

  public update(item: Pattern): Observable<void | number> {
    throw new Error('Method not implemented.');
  }

  public deleteMulti?(ids: number[]): Observable<MultiDeleteResponse[]> {
    throw new Error('Method not implemented.');
  }
}
