<div class="full-width bms-theme flex-column grid-with-actions-container">
  @if (canShowActions) {
    <div class="actions">
      <div class="flex-row gap-8">
        <button
          type="button"
          mat-icon-button
          [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EXPORT' | bmsTranslate"
          matTooltipPosition="above"
          matTooltipClass="bms-theme"
          tabindex="-1"
          (click)="exportData()"
        >
          <mat-icon class="icon" svgIcon="solid-download"></mat-icon>
        </button>
        @if (showCustomSettingsButton) {
          <vdw-settings-icon-button alignRight="true" [showCustomSettingsButton]="true" (customSettingsButtonClicked)="showCustomSettings.emit()" />
        }
        @if (withNewClicked) {
          <button class="action-button" mat-flat-button (click)="withNewClicked($event)">{{ getNewButtonText() }}</button>
        }
      </div>
    </div>
  }

  <div #gridContainer class="full-width flex-column flex-grow">
    <ag-grid-angular #grid class="full-width full-max-height ag-auto-grow-grid-height" [rowData]="rowData" [gridOptions]="gridOptions" />
  </div>
</div>
