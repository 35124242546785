import {Injectable} from '@angular/core';
import {intComparator} from '@application/helper/int-comparator';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {ColDefBuilderFactoryService, GridOptionsBuilder, NoDataOverlayComponentParams, OverlayComponentParams, StringUtils, TranslateService} from '@vdw/angular-component-library';
import {ColDef, RowNode, ValueGetterParams} from 'ag-grid-community';

@Injectable()
export class PatternOverviewService {
  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withHeaderName('GENERAL.NAME').withColIdAndField('name', true).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.DATETIME.DURATION')
        .withColId('numDays')
        .withValueGetter((params: ValueGetterParams) => `${params.data.numDays} ${this.translate.instant('MACHINE.SETTINGS.DAYS', {count: params.data.numDays}).toLowerCase()}`, true)
        .withComparator((valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode) => intComparator(nodeA?.data?.numDays, nodeB?.data?.numDays))
        .withCellClass('right')
        .build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant(NavigationUtils.getNavigationData(NavigationId.PATTERN_MODULE).navigationKey, {count: 1}),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }
}
