import {RouteUtils} from '@application/helper/routing/route-utils';
import {Permission} from '@domain/profile/permission.enum';
import {PageUrls} from '@domain/textile-data/page-urls';
import {Translations} from '@domain/textile-data/translations';

export class LastModifiedCardUtils {
  private static productionOrderTranslationKey = 'PRODUCTION_ORDER.PRODUCTION_ORDER';
  private static dataUnitSetupTranslationKey = 'BMSCONFIG.DATA_UNIT_SETUP.DATA_UNIT_SETUP';
  private static finishingTranslationKey = 'TEXTILE_DATA.FINISHING.FINISHING';
  private static DEVICE_TEMPLATE_COUNTER_TRANSLATION_KEY = 'BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS';
  private static DEVICE_TEMPLATE_CONFIGURATION_TRANSLATION_KEY = 'BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS';

  public static getPageUrls(type: string): PageUrls {
    return {
      machineQuality: {
        add: RouteUtils.paths.texStyle.machineQuality.addMachineQuality.absolutePath,
        edit: RouteUtils.paths.texStyle.machineQuality.editMachineQuality.absolutePath,
        overview: RouteUtils.paths.texStyle.machineQuality.absolutePath,
        duplicate: RouteUtils.paths.texStyle.machineQuality.duplicateMachineQuality.absolutePath
      },
      qualityFinishing: {
        add: RouteUtils.paths.texStyle.machineQuality.addFinishing.absolutePath,
        edit: '',
        duplicate: ''
      },
      yarnType: {
        add: RouteUtils.paths.texStyle.yarn.addYarn.absolutePath,
        edit: RouteUtils.paths.texStyle.yarn.editYarn.absolutePath,
        overview: RouteUtils.paths.texStyle.yarn.absolutePath,
        duplicate: RouteUtils.paths.texStyle.yarn.duplicateYarn.absolutePath
      },
      coloredYarn: {
        add: RouteUtils.paths.texStyle.coloredYarn.addColoredYarn.absolutePath,
        edit: RouteUtils.paths.texStyle.coloredYarn.editColoredYarn.absolutePath,
        overview: RouteUtils.paths.texStyle.coloredYarn.absolutePath,
        duplicate: RouteUtils.paths.texStyle.coloredYarn.duplicateColoredYarn.absolutePath
      },
      finishingTemplate: {
        add: RouteUtils.paths.texStyle.finishingTemplate.addFinishingTemplate.absolutePath,
        edit: RouteUtils.paths.texStyle.finishingTemplate.editFinishingTemplate.absolutePath,
        overview: RouteUtils.paths.texStyle.finishingTemplate.absolutePath,
        duplicate: RouteUtils.paths.texStyle.finishingTemplate.duplicateFinishingTemplate.absolutePath
      },
      color: {
        add: RouteUtils.paths.texStyle.color.addColor.absolutePath,
        edit: RouteUtils.paths.texStyle.color.editColor.absolutePath,
        overview: RouteUtils.paths.texStyle.color.absolutePath,
        duplicate: RouteUtils.paths.texStyle.color.duplicateColor.absolutePath,
        settings: RouteUtils.paths.texStyle.color.colorSettings.absolutePath
      },
      creel: {
        add: RouteUtils.paths.texStyle.creel.addCreel.absolutePath,
        edit: RouteUtils.paths.texStyle.creel.editCreel.absolutePath,
        overview: RouteUtils.paths.texStyle.creel.absolutePath,
        duplicate: RouteUtils.paths.texStyle.creel.duplicateCreel.absolutePath,
        settings: RouteUtils.paths.texStyle.creel.creelSettings.absolutePath
      },
      weaveStructure: {
        add: RouteUtils.paths.texStyle.weaveStructure.addWeaveStructure.absolutePath,
        edit: RouteUtils.paths.texStyle.weaveStructure.editWeaveStructure.absolutePath,
        overview: RouteUtils.paths.texStyle.weaveStructure.overviewWeaveStructure.absolutePath,
        duplicate: RouteUtils.paths.texStyle.weaveStructure.duplicateWeaveStructure.absolutePath
      },
      manageWeaveStructure: {
        add: RouteUtils.paths.texStyle.weaveStructure.manageAddWeaveStructure.absolutePath,
        overview: RouteUtils.paths.texStyle.weaveStructure.manageOverviewWeaveStructure.absolutePath,
        duplicate: RouteUtils.paths.texStyle.weaveStructure.duplicateWeaveStructure.absolutePath
      },
      groupedWeaveStructure: {
        overview: RouteUtils.paths.texStyle.groupedWeaveStructure.overviewGroupedWeaveStructure.absolutePath,
        add: RouteUtils.paths.texStyle.groupedWeaveStructure.addGroupedWeaveStructure.absolutePath,
        edit: RouteUtils.paths.texStyle.groupedWeaveStructure.editGroupedWeaveStructure.absolutePath,
        duplicate: RouteUtils.paths.texStyle.groupedWeaveStructure.duplicateGroupedWeaveStructure.absolutePath
      },
      weavePattern: {
        add: '',
        edit: ''
      },
      colorSet: {
        add: RouteUtils.paths.texStyle.colorSet.addColorSet.absolutePath,
        edit: RouteUtils.paths.texStyle.colorSet.editColorSet.absolutePath,
        overview: RouteUtils.paths.texStyle.colorSet.absolutePath,
        duplicate: RouteUtils.paths.texStyle.colorSet.duplicateColorSet.absolutePath,
        settings: RouteUtils.paths.texStyle.colorSet.colorSetSettings.absolutePath
      },
      yarnSet: {
        add: RouteUtils.paths.texStyle.yarnSet.addYarnSet.absolutePath,
        edit: RouteUtils.paths.texStyle.yarnSet.editYarnSet.absolutePath,
        duplicate: RouteUtils.paths.texStyle.yarnSet.duplicateYarnSet.absolutePath,
        overview: RouteUtils.paths.texStyle.yarnSet.absolutePath,
        settings: RouteUtils.paths.texStyle.yarnSet.yarnSetSettings.absolutePath
      },
      coloredYarnSet: {
        overview: RouteUtils.paths.texStyle.coloredYarnSet.absolutePath,
        add: RouteUtils.paths.texStyle.coloredYarnSet.addColoredYarnSet.absolutePath,
        edit: RouteUtils.paths.texStyle.coloredYarnSet.editColoredYarnSet.absolutePath,
        duplicate: RouteUtils.paths.texStyle.coloredYarnSet.duplicateColoredYarnSet.absolutePath
      },
      weftColoredYarnSet: {
        overview: RouteUtils.paths.texStyle.weftColoredYarnSet.absolutePath,
        add: RouteUtils.paths.texStyle.weftColoredYarnSet.addWeftColoredYarnSet.absolutePath,
        edit: RouteUtils.paths.texStyle.weftColoredYarnSet.editWeftColoredYarnSet.absolutePath,
        duplicate: RouteUtils.paths.texStyle.weftColoredYarnSet.duplicateWeftColoredYarnSet.absolutePath
      },
      companyWeaveStructure: {
        overview: RouteUtils.paths.texStyle.companyWeaveStructure.absolutePath,
        add: '',
        edit: ''
      },
      productionSchedule: {
        overview: RouteUtils.paths.texFab.productionOrder.absolutePath,
        add: RouteUtils.paths.texFab.productionOrder.addProductionOrder.absolutePath,
        edit: RouteUtils.paths.texFab.productionOrder.editProductionOrder.absolutePath,
        duplicate: RouteUtils.paths.texFab.productionOrder.duplicateProductionOrder.absolutePath,
        settings: RouteUtils.paths.texFab.productionOrder.settings.absolutePath
      },
      productionScheduleNew: {
        overview: RouteUtils.paths.texFab.productionOrder.absolutePath,
        add: RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView.absolutePath,
        edit: RouteUtils.paths.texFab.productionOrder.editProductionOrderNewView.absolutePath,
        duplicate: RouteUtils.paths.texFab.productionOrder.duplicateProductionOrderNewView.absolutePath,
        settings: RouteUtils.paths.texFab.productionOrder.settings.absolutePath
      },
      productionOrderWeaving: {
        overview: RouteUtils.paths.texFab.weaving.productionOrder.absolutePath,
        add: RouteUtils.paths.texFab.weaving.addProductionOrder.absolutePath,
        edit: RouteUtils.paths.texFab.weaving.editProductionOrder.absolutePath,
        duplicate: RouteUtils.paths.texFab.weaving.duplicateProductionOrder.absolutePath,
        settings: RouteUtils.paths.texFab.weaving.settings.absolutePath
      },
      tuftProductionOrder: {
        overview: RouteUtils.paths.texFab.tufting.productionOrder.absolutePath,
        add: RouteUtils.paths.texFab.tufting.addProductionOrder.absolutePath,
        edit: RouteUtils.paths.texFab.tufting.editProductionOrder.absolutePath,
        duplicate: RouteUtils.paths.texFab.tufting.duplicateProductionOrder.absolutePath,
        settings: RouteUtils.paths.texFab.tufting.settings.absolutePath
      },
      plasticProductionOrder: {
        overview: RouteUtils.paths.texFab.plastic.productionOrder.absolutePath,
        add: RouteUtils.paths.texFab.plastic.addProductionOrder.absolutePath,
        edit: RouteUtils.paths.texFab.plastic.editProductionOrder.absolutePath,
        duplicate: RouteUtils.paths.texFab.plastic.duplicateProductionOrder.absolutePath
      },
      customer: {
        overview: RouteUtils.paths.texFab.customer.absolutePath,
        add: RouteUtils.paths.texFab.customer.addCustomer.absolutePath,
        edit: RouteUtils.paths.texFab.customer.editCustomer.absolutePath
      },
      article: {
        overview: RouteUtils.paths.texFab.article.absolutePath,
        add: RouteUtils.paths.texFab.article.addArticle.absolutePath,
        edit: RouteUtils.paths.texFab.article.editArticle.absolutePath,
        duplicate: RouteUtils.paths.texFab.article.duplicateArticle.absolutePath
      },
      salesOrder: {
        overview: RouteUtils.paths.texFab.salesOrder.absolutePath,
        add: RouteUtils.paths.texFab.salesOrder.addSalesOrder.absolutePath,
        edit: RouteUtils.paths.texFab.salesOrder.editSalesOrder.absolutePath,
        duplicate: RouteUtils.paths.texFab.salesOrder.duplicateSalesOrder.absolutePath
      },
      weaveProduct: {
        overview: RouteUtils.paths.texStyle.weaveProduct.absolutePath,
        add: RouteUtils.paths.texStyle.weaveProduct.addWeaveProduct.absolutePath,
        edit: RouteUtils.paths.texStyle.weaveProduct.editWeaveProduct.absolutePath,
        duplicate: RouteUtils.paths.texStyle.weaveProduct.duplicateWeaveProduct.absolutePath
      },
      productConfiguration: {
        overview: RouteUtils.paths.texEdit.productConfiguration.absolutePath,
        add: RouteUtils.paths.texEdit.addProductConfiguration.absolutePath,
        edit: RouteUtils.paths.texEdit.editProductConfiguration.absolutePath
      },
      dataUnitSetupCatalog: {
        overview: RouteUtils.paths.settings.dataUnitSetupCatalog.absolutePath,
        add: RouteUtils.paths.settings.dataUnitSetupCatalog.addDataUnitSetupCatalog.absolutePath,
        edit: RouteUtils.paths.settings.dataUnitSetupCatalog.editDataUnitSetupCatalog.absolutePath,
        duplicate: RouteUtils.paths.settings.dataUnitSetupCatalog.duplicateDataUnitSetupCatalog.absolutePath
      },
      dataUnitSetup: {
        overview: RouteUtils.paths.settings.dataUnitSetup.absolutePath,
        add: RouteUtils.paths.settings.dataUnitSetup.addDataUnitSetup.absolutePath,
        edit: RouteUtils.paths.settings.dataUnitSetup.editDataUnitSetup.absolutePath
      },
      deviceTemplateCatalog: {
        overview: RouteUtils.paths.settings.deviceTemplateCatalog.absolutePath,
        add: RouteUtils.paths.settings.deviceTemplateCatalog.addDeviceTemplateCatalog.absolutePath,
        edit: RouteUtils.paths.settings.deviceTemplateCatalog.editDeviceTemplateCatalog.absolutePath,
        duplicate: RouteUtils.paths.settings.deviceTemplateCatalog.duplicateDeviceTemplateCatalog.absolutePath
      },
      deviceTemplateCounterCatalog: {
        add: RouteUtils.paths.settings.deviceTemplateCatalog.addCounterCatalog.absolutePath,
        edit: RouteUtils.paths.settings.deviceTemplateCatalog.editCounterCatalog.absolutePath,
        duplicate: RouteUtils.paths.settings.deviceTemplateCatalog.duplicateCounterCatalog.absolutePath
      },
      deviceTemplateConfigurationCatalog: {
        add: RouteUtils.paths.settings.deviceTemplateCatalog.addConfigurationCatalog.absolutePath,
        edit: RouteUtils.paths.settings.deviceTemplateCatalog.editConfigurationCatalog.absolutePath,
        duplicate: RouteUtils.paths.settings.deviceTemplateCatalog.duplicateConfigurationCatalog.absolutePath
      },
      deviceTemplate: {
        overview: RouteUtils.paths.settings.deviceTemplate.absolutePath,
        add: RouteUtils.paths.settings.deviceTemplate.addDeviceTemplate.absolutePath,
        edit: RouteUtils.paths.settings.deviceTemplate.editDeviceTemplate.absolutePath,
        duplicate: RouteUtils.paths.settings.deviceTemplate.duplicateDeviceTemplate.absolutePath
      },
      deviceTemplateCounter: {
        add: RouteUtils.paths.settings.deviceTemplate.addCounter.absolutePath,
        edit: RouteUtils.paths.settings.deviceTemplate.editCounter.absolutePath,
        duplicate: RouteUtils.paths.settings.deviceTemplate.duplicateCounter.absolutePath
      },
      deviceTemplateConfiguration: {
        add: RouteUtils.paths.settings.deviceTemplate.addConfiguration.absolutePath,
        edit: RouteUtils.paths.settings.deviceTemplate.editConfiguration.absolutePath,
        duplicate: RouteUtils.paths.settings.deviceTemplate.duplicateConfiguration.absolutePath
      },
      reportItem: {
        overview: RouteUtils.paths.settings.reportItem.absolutePath
      },
      event: {
        overview: RouteUtils.paths.settings.event.absolutePath,
        add: RouteUtils.paths.settings.event.addEvent.absolutePath,
        duplicate: RouteUtils.paths.settings.event.duplicateEvent.absolutePath,
        edit: RouteUtils.paths.settings.event.editEvent.absolutePath
      },
      eventAlert: {
        add: '',
        duplicate: '',
        edit: ''
      },
      tuftProduct: {
        overview: RouteUtils.paths.texStyle.tuftProduct.absolutePath,
        add: RouteUtils.paths.texStyle.tuftProduct.addTuftProduct.absolutePath,
        duplicate: RouteUtils.paths.texStyle.tuftProduct.duplicateTuftProduct.absolutePath,
        edit: RouteUtils.paths.texStyle.tuftProduct.editTuftProduct.absolutePath
      },
      plasticProduct: {
        overview: RouteUtils.paths.texStyle.plasticProduct.absolutePath,
        add: RouteUtils.paths.texStyle.plasticProduct.addPlasticProduct.absolutePath,
        edit: RouteUtils.paths.texStyle.plasticProduct.editPlasticProduct.absolutePath,
        duplicate: RouteUtils.paths.texStyle.plasticProduct.duplicatePlasticProduct.absolutePath
      },
      operator: {
        overview: RouteUtils.paths.settings.operator.absolutePath,
        add: RouteUtils.paths.settings.operator.addOperator.absolutePath,
        edit: RouteUtils.paths.settings.operator.editOperator.absolutePath,
        duplicate: RouteUtils.paths.settings.operator.duplicateOperator.absolutePath
      },
      pathLayoutTemplate: {
        overview: RouteUtils.paths.texFab.pathLayoutTemplate.absolutePath,
        add: RouteUtils.paths.texFab.pathLayoutTemplate.addPathLayoutTemplate.absolutePath,
        edit: RouteUtils.paths.texFab.pathLayoutTemplate.editPathLayoutTemplate.absolutePath,
        duplicate: RouteUtils.paths.texFab.pathLayoutTemplate.duplicatePathLayoutTemplate.absolutePath,
        settings: RouteUtils.paths.texFab.pathLayoutTemplate.pathLayoutTemplateSettings.absolutePath
      },
      resourceType: {
        overview: RouteUtils.paths.settings.resourceType.absolutePath,
        add: RouteUtils.paths.settings.resourceType.addResourceType.absolutePath,
        edit: RouteUtils.paths.settings.resourceType.editResourceType.absolutePath,
        duplicate: RouteUtils.paths.settings.resourceType.duplicateResourceType.absolutePath
      },
      physicalQuantity: {
        overview: RouteUtils.paths.settings.physicalQuantity.absolutePath,
        add: RouteUtils.paths.settings.physicalQuantity.addPhysicalQuantity.absolutePath,
        edit: RouteUtils.paths.settings.physicalQuantity.editPhysicalQuantity.absolutePath,
        duplicate: RouteUtils.paths.settings.physicalQuantity.duplicatePhysicalQuantity.absolutePath
      },
      declaration: {
        overview: RouteUtils.paths.settings.absolutePath
      },
      automaticStopGroup: {
        overview: RouteUtils.paths.settings.absolutePath
      },
      tool: {
        overview: RouteUtils.paths.utilities.tool.absolutePath,
        add: RouteUtils.paths.utilities.tool.addTool.absolutePath,
        edit: RouteUtils.paths.utilities.tool.editTool.absolutePath,
        duplicate: RouteUtils.paths.utilities.tool.duplicateTool.absolutePath
      },
      rejectReason: {
        overview: RouteUtils.paths.settings.rejectReason.absolutePath,
        add: RouteUtils.paths.settings.rejectReason.addRejectReason.absolutePath,
        edit: RouteUtils.paths.settings.rejectReason.editRejectReason.absolutePath,
        duplicate: RouteUtils.paths.settings.rejectReason.duplicateRejectReason.absolutePath
      },
      fixedSchedule: {
        overview: RouteUtils.paths.texStyle.fixedSchedule.absolutePath,
        add: RouteUtils.paths.texStyle.fixedSchedule.addFixedSchedule.absolutePath,
        edit: RouteUtils.paths.texStyle.fixedSchedule.editFixedSchedule.absolutePath,
        duplicate: RouteUtils.paths.texStyle.fixedSchedule.duplicateFixedSchedule.absolutePath
      },
      buggy: {
        overview: RouteUtils.paths.texStyle.buggy.absolutePath,
        add: RouteUtils.paths.texStyle.buggy.addBuggy.absolutePath,
        edit: RouteUtils.paths.texStyle.buggy.editBuggy.absolutePath,
        duplicate: RouteUtils.paths.texStyle.buggy.duplicateBuggy.absolutePath
      },
      shift: {
        overview: RouteUtils.paths.calendar.shift.absolutePath,
        add: RouteUtils.paths.calendar.shift.addShift.absolutePath,
        edit: RouteUtils.paths.calendar.shift.editShift.absolutePath,
        duplicate: RouteUtils.paths.calendar.shift.duplicateShift.absolutePath
      }
    }[type];
  }

  public static getTranslations(type: string): Translations {
    return {
      machineQuality: {
        entity: 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY',
        delete: 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY'
      },
      qualityFinishing: {
        delete: LastModifiedCardUtils.finishingTranslationKey
      },
      yarnType: {
        entity: 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE',
        delete: 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE'
      },
      coloredYarn: {
        entity: 'TEXTILE_DATA.COLORED_YARN.COLORED_YARN',
        delete: 'TEXTILE_DATA.COLORED_YARN.COLORED_YARN'
      },
      finishingTemplate: {
        entity: 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE',
        delete: 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE'
      },
      color: {
        entity: 'TEXTILE_DATA.COLOR.COLOR',
        delete: 'TEXTILE_DATA.COLOR.COLOR'
      },
      creel: {
        entity: 'TEXTILE_DATA.CREEL.CREEL',
        delete: 'TEXTILE_DATA.CREEL.CREEL'
      },
      weaveStructure: {
        entity: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE',
        delete: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE'
      },
      manageWeaveStructure: {
        entity: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE',
        delete: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE'
      },
      groupedWeaveStructure: {
        entity: 'TEXTILE_DATA.GROUPED_WEAVESTRUCTURE.GROUPED_WEAVESTRUCTURE',
        delete: 'TEXTILE_DATA.GROUPED_WEAVESTRUCTURE.GROUPED_WEAVESTRUCTURE'
      },
      finishing: {
        entity: LastModifiedCardUtils.finishingTranslationKey,
        delete: LastModifiedCardUtils.finishingTranslationKey
      },
      colorSet: {
        entity: 'TEXTILE_DATA.COLOR_SET.COLOR_SET',
        delete: 'TEXTILE_DATA.COLOR_SET.COLOR_SET'
      },
      yarnSet: {
        entity: 'TEXTILE_DATA.YARN_SET.YARN_SET',
        delete: 'TEXTILE_DATA.YARN_SET.YARN_SET'
      },
      coloredYarnSet: {
        entity: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET',
        delete: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET'
      },
      weftColoredYarnSet: {
        entity: 'TEXTILE_DATA.WEFT_COLORED_YARN_SET.WEFT_COLORED_YARN_SET',
        delete: 'TEXTILE_DATA.WEFT_COLORED_YARN_SET.WEFT_COLORED_YARN_SET'
      },
      companyWeaveStructure: {
        entity: 'TEXTILE_DATA.COMPANY_WEAVE_STRUCTURE.COMPANY_WEAVE_STRUCTURE'
      },
      productionSchedule: {
        entity: LastModifiedCardUtils.productionOrderTranslationKey,
        delete: LastModifiedCardUtils.productionOrderTranslationKey
      },
      productionScheduleNew: {
        entity: LastModifiedCardUtils.productionOrderTranslationKey,
        delete: LastModifiedCardUtils.productionOrderTranslationKey
      },
      productionOrderWeaving: {
        entity: LastModifiedCardUtils.productionOrderTranslationKey,
        delete: LastModifiedCardUtils.productionOrderTranslationKey
      },
      customer: {
        entity: 'CUSTOMERS.CUSTOMER',
        delete: 'CUSTOMERS.CUSTOMER'
      },
      article: {
        entity: 'ARTICLES.ARTICLE',
        delete: 'ARTICLES.ARTICLE'
      },
      salesOrder: {
        entity: 'SALES_ORDERS.SALES_ORDER',
        delete: 'SALES_ORDERS.SALES_ORDER'
      },
      weavePattern: {
        entity: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_PATTERN',
        delete: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_PATTERN'
      },
      weaveProduct: {
        entity: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT',
        delete: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT'
      },
      productConfiguration: {
        entity: 'PRODUCT_CONFIGURATION.PRODUCT_CONFIGURATION',
        delete: 'PRODUCT_CONFIGURATION.PRODUCT_CONFIGURATION'
      },
      dataUnitSetupCatalog: {
        entity: 'BMSCONFIG.DATA_UNIT_SETUP_CATALOG.DATA_UNIT_SETUP_CATALOG',
        delete: LastModifiedCardUtils.dataUnitSetupTranslationKey
      },
      dataUnitSetup: {
        entity: LastModifiedCardUtils.dataUnitSetupTranslationKey,
        delete: LastModifiedCardUtils.dataUnitSetupTranslationKey
      },
      deviceTemplateCatalog: {
        entity: 'BMSCONFIG.DEVICE_TEMPLATES_CATALOG.DEVICE_TEMPLATES_CATALOG',
        delete: 'BMSCONFIG.DEVICE_TEMPLATES_CATALOG.DEVICE_TEMPLATES_CATALOG'
      },
      deviceTemplateCounterCatalog: {
        entity: LastModifiedCardUtils.DEVICE_TEMPLATE_COUNTER_TRANSLATION_KEY,
        delete: LastModifiedCardUtils.DEVICE_TEMPLATE_COUNTER_TRANSLATION_KEY
      },
      deviceTemplateConfigurationCatalog: {
        entity: LastModifiedCardUtils.DEVICE_TEMPLATE_CONFIGURATION_TRANSLATION_KEY,
        delete: LastModifiedCardUtils.DEVICE_TEMPLATE_CONFIGURATION_TRANSLATION_KEY
      },
      deviceTemplate: {
        entity: 'BMSCONFIG.DEVICE_TEMPLATES.DEVICE_TEMPLATE',
        delete: 'BMSCONFIG.DEVICE_TEMPLATES.DEVICE_TEMPLATE'
      },
      deviceTemplateCounter: {
        entity: LastModifiedCardUtils.DEVICE_TEMPLATE_COUNTER_TRANSLATION_KEY,
        delete: LastModifiedCardUtils.DEVICE_TEMPLATE_COUNTER_TRANSLATION_KEY
      },
      deviceTemplateConfiguration: {
        entity: LastModifiedCardUtils.DEVICE_TEMPLATE_CONFIGURATION_TRANSLATION_KEY,
        delete: LastModifiedCardUtils.DEVICE_TEMPLATE_CONFIGURATION_TRANSLATION_KEY
      },
      event: {
        entity: 'BMSCONFIG.EVENTS.EVENT',
        delete: 'BMSCONFIG.EVENTS.EVENT'
      },
      eventAlert: {
        entity: 'BMSCONFIG.EVENTS.ALERT',
        delete: 'BMSCONFIG.EVENTS.ALERT'
      },
      tuftProduct: {
        entity: 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT',
        delete: 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT'
      },
      tuftProductionOrder: {
        entity: LastModifiedCardUtils.productionOrderTranslationKey,
        delete: LastModifiedCardUtils.productionOrderTranslationKey
      },
      operator: {
        entity: 'MACHINE.OPERATORS.OPERATOR',
        delete: 'MACHINE.OPERATORS.OPERATOR'
      },
      pathLayoutTemplate: {
        entity: 'PATH_LAYOUT_TEMPLATE.PATH_LAYOUT_TEMPLATE',
        delete: 'PATH_LAYOUT_TEMPLATE.PATH_LAYOUT_TEMPLATE'
      },
      resourceType: {
        entity: 'BMSCONFIG.RESOURCE_TYPES.RESOURCE_TYPE',
        delete: 'BMSCONFIG.RESOURCE_TYPES.RESOURCE_TYPE'
      },
      physicalQuantity: {
        entity: 'BMSCONFIG.PHYSICAL_QUANTITIES.PHYSICAL_QUANTITY',
        delete: 'BMSCONFIG.PHYSICAL_QUANTITIES.PHYSICAL_QUANTITY'
      },
      plasticProduct: {
        entity: 'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT',
        delete: 'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT'
      },
      plasticProductionOrder: {
        entity: LastModifiedCardUtils.productionOrderTranslationKey,
        delete: LastModifiedCardUtils.productionOrderTranslationKey
      },
      tool: {
        entity: 'UTILITIES.TOOL.TOOL',
        delete: 'UTILITIES.TOOL.TOOL'
      },
      stockLocation: {
        entity: 'UTILITIES.TOOL.STOCK_LOCATION',
        delete: 'UTILITIES.TOOL.STOCK_LOCATION'
      },
      rawMaterial: {
        entity: 'TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL',
        delete: 'TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL'
      },
      rejectReason: {
        entity: 'BMSCONFIG.REJECT_REASON.REJECT_REASON',
        delete: 'BMSCONFIG.REJECT_REASON.REJECT_REASON'
      },
      buggy: {
        entity: 'PLANNING.BUGGY.BUGGY',
        delete: 'PLANNING.BUGGY.BUGGY'
      },
      fixedSchedule: {
        entity: 'TEXTILE_DATA.FIXED_ORDER.FIXED_ORDER',
        delete: 'TEXTILE_DATA.FIXED_ORDER.FIXED_ORDER'
      },
      shift: {
        entity: 'CALENDAR.SHIFT.SHIFT',
        delete: 'CALENDAR.SHIFT.SHIFT'
      },
      reportItem: {
        delete: 'BMSCONFIG.REPORT_ITEMS.REPORT_ITEM'
      }
    }[type];
  }

  public static hasCustomSettings(type: string): boolean {
    return {
      machineQuality: true,
      yarnType: true,
      finishingTemplate: true,
      color: true,
      creel: true,
      weaveStructure: true,
      manageWeaveStructure: true,
      groupedWeaveStructure: false,
      weavePattern: false,
      finishing: false,
      colorSet: true,
      yarnSet: true,
      coloredYarnSet: false,
      weftColoredYarnSet: false,
      companyWeaveStructure: false,
      productionSchedule: true,
      productionScheduleNew: true,
      productionOrderWeaving: true,
      tuftProductionOrder: true,
      customer: false,
      article: false,
      event: false,
      salesOrder: false,
      productConfiguration: true,
      dataUnitSetupCatalog: true,
      dataUnitSetup: true,
      pathLayoutTemplate: true
    }[type];
  }

  public static getPermissionToModifyItems(type: string): Permission {
    return {
      machineQuality: Permission.TEXSTYLE_MACHINE_QUALITY_EDIT,
      qualityFinishing: Permission.TEXSTYLE_FINISHING_EDIT,
      yarnType: Permission.TEXSTYLE_YARN_EDIT,
      finishingTemplate: Permission.TEXSTYLE_FINISHING_EDIT,
      color: Permission.TEXSTYLE_COLOR_EDIT,
      coloredYarn: Permission.ACCESS_ALL,
      weaveProduct: Permission.TEXSTYLE_WEAVE_PRODUCT_EDIT,
      creel: Permission.TEXSTYLE_CREEL_EDIT,
      weaveStructure: Permission.TEXSTYLE_EDIT_WEAVE_STRUCTURES,
      manageWeaveStructure: Permission.MANAGE_WEAVE_STRUCTURE_EDIT,
      groupedWeaveStructure: Permission.ACCESS_ALL,
      weavePattern: Permission.TEXSTYLE_WEAVE_STRUCTURES_EDIT_PATTERN,
      finishing: Permission.TEXSTYLE_FINISHING_EDIT,
      colorSet: Permission.TEXSTYLE_COLORSET_EDIT,
      yarnSet: Permission.TEXSTYLE_YARNSET_EDIT,
      coloredYarnSet: Permission.TEXSTYLE_COLOREDYARNSET_EDIT,
      weftColoredYarnSet: Permission.TEXSTYLE_WEFTCOLOREDYARNSET_EDIT,
      companyWeaveStructure: Permission.COMPANY_WEAVE_STRUCTURE_EDIT,
      productionSchedule: Permission.TEXFAB_SCHEDULE,
      productionScheduleNew: Permission.TEXFAB_SCHEDULE,
      productionOrderWeaving: Permission.TEXFAB_WEAVE_ORDER_EDIT,
      customer: Permission.TEXFAB_CUSTOMER_EDIT,
      article: Permission.TEXFAB_ARTICLE_EDIT,
      salesOrder: Permission.TEXFAB_SALES_ORDER_EDIT,
      productConfiguration: Permission.TEXFAB_SCHEDULE,
      dataUnitSetup: Permission.SETTINGS_DATA_UNIT_SETUP_VIEW,
      dataUnitSetupCatalog: Permission.SETTINGS_DATA_UNIT_SETUP_CATALOG_VIEW,
      deviceTemplateCatalog: Permission.ADMIN_DEVICE_TEMPLATE_EDIT,
      deviceTemplateCounterCatalog: Permission.ADMIN_DEVICE_TEMPLATE_EDIT,
      deviceTemplateConfigurationCatalog: Permission.ADMIN_DEVICE_TEMPLATE_EDIT,
      deviceTemplate: Permission.DEVICE_TEMPLATE_EDIT,
      deviceTemplateCounter: Permission.DEVICE_TEMPLATE_EDIT,
      deviceTemplateConfiguration: Permission.DEVICE_TEMPLATE_EDIT,
      event: Permission.ALERT_EDIT_EVENTS,
      tuftProduct: Permission.TEXSTYLE_TUFT_PRODUCT_EDIT,
      tuftProductionOrder: Permission.TEXFAB_TUFT_ORDER_EDIT,
      operator: Permission.SETTINGS_OPERATOR_EDIT,
      pathLayoutTemplate: Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_EDIT,
      reportItem: Permission.SETTINGS_REPORT_ITEM_EDIT,
      tool: Permission.TEXSTYLE_PLASTIC_TOOL_EDIT,
      buggy: Permission.EDIT_BUGGIES,
      fixedSchedule: Permission.FIXED_SCHEDULE_EDIT
    }[type];
  }

  public static canShowConflictsDialog(type: string): boolean {
    return {
      machineQuality: true,
      yarnType: true,
      finishingTemplate: true,
      color: true,
      creel: true,
      weaveStructure: true,
      manageWeaveStructure: true,
      groupedWeaveStructure: false,
      weavePattern: false,
      finishing: true,
      colorSet: true,
      yarnSet: true,
      coloredYarnSet: true,
      weftColoredYarnSet: true,
      companyWeaveStructure: false,
      productionSchedule: true,
      productionScheduleNew: true,
      productionOrderWeaving: false,
      customers: true,
      article: true,
      productConfiguration: false,
      event: false,
      salesOrder: true,
      deviceTemplate: true,
      weaveProduct: true,
      tuftProduct: true
    }[type];
  }
}
