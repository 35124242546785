import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {VirtualScrollerModule} from '@iharbeck/ngx-virtual-scroller';
import {LabelModule} from '../custom-components/ellipsis-label/label.module';
import {DateTimePipeModule} from '../custom-pipes/date-time-pipe/date-time-pipe.module';
import {TranslationModule} from '../translation/translation.module';
import {CurrentTimeIndicatorComponent} from './planning-display/current-time-indicator/current-time-indicator.component';
import {DateHeaderRowComponent} from './planning-display/date-header-row/date-header-row.component';
import {HourHeaderRowComponent} from './planning-display/hour-header-row/hour-header-row.component';
import {PlanningDisplayComponent} from './planning-display/planning-display.component';
import {PlanningGridComponent} from './planning-display/planning-grid/planning-grid.component';
import {PlanningItemDisplayComponent} from './planning-display/planning-item-display/planning-item-display.component';
import {RegimesIndicatorComponent} from './planning-display/regimes-indicator/regimes-indicator.component';
import {TimelinePositionDirective} from './planning-display/timeline-position/timeline-position.directive';
import {PlanningGroupComponent} from './planning-items/planning-group/planning-group.component';
import {PlanningItemDirective} from './planning-items/planning-item/planning-item.directive';
import {PlanningRowComponent} from './planning-row/planning-row.component';
import {ShiftScheduleComponent} from './shift-schedule/shift-schedule.component';
import {ShiftComponent} from './shift-schedule/shift.component';

@NgModule({
  imports: [CommonModule, MatIconModule, LabelModule, MatButtonModule, TranslationModule, MatTooltipModule, VirtualScrollerModule, DateTimePipeModule],
  declarations: [
    PlanningDisplayComponent,
    PlanningGridComponent,
    PlanningRowComponent,
    DateHeaderRowComponent,
    HourHeaderRowComponent,
    ShiftComponent,
    ShiftScheduleComponent,
    CurrentTimeIndicatorComponent,
    RegimesIndicatorComponent,
    PlanningItemDirective,
    PlanningGroupComponent,
    PlanningItemDisplayComponent,
    TimelinePositionDirective
  ],
  exports: [PlanningDisplayComponent, PlanningGridComponent, PlanningRowComponent, ShiftScheduleComponent, ShiftComponent, PlanningItemDirective, PlanningGroupComponent]
})
export class PlanningDisplayModule {}
