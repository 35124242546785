import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatCalendar, MatCalendarHeader, MatDatepickerIntl} from '@angular/material/datepicker';
import {isEqual} from 'lodash-es';
import {DateTimeFormat} from '../../../common/datetime-format.enum';
import {DateTimePipe} from '../../../custom-pipes/date-time-pipe/date-time.pipe';

@Component({
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerHeaderComponent extends MatCalendarHeader<Date> {
  public constructor(
    private readonly dateTimePipe: DateTimePipe,
    intl: MatDatepickerIntl,
    calendar: MatCalendar<Date>,
    dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DATE_FORMATS) dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(intl, calendar, dateAdapter, dateFormats, changeDetectorRef);
  }

  public get periodButtonText(): string {
    let result = super.periodButtonText;

    if (isEqual(this.calendar.currentView, 'month')) {
      result = this.dateTimePipe.transform(this.calendar.activeDate, DateTimeFormat.MONTH_YEAR);
    }

    return result;
  }
}
