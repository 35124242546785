import {DOCUMENT} from '@angular/common';
import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationFunctionData} from '@application/helper/navigation-helper/navigation-function-data.interface';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {SalesOrderStubFactory} from '@application/helper/sales-order/sales-order-stub-factory';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {Article} from '@domain/article/article';
import {ConflictType} from '@domain/conflicts/conflict-type';
import {Customer} from '@domain/customer/customer';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {ArticleForSalesOrderLine} from '@domain/sales-order/article-for-sales-order-line';
import {CustomerForSalesOrder} from '@domain/sales-order/customer-for-sales-order';
import {OrderLineForSalesOrder} from '@domain/sales-order/order-line-for-sales-order';
import {PaymentStatus} from '@domain/sales-order/payment-status';
import {SalesOrder} from '@domain/sales-order/sales-order';
import {SalesOrderStatus} from '@domain/sales-order/sales-order-status';
import {ARTICLES, Articles} from '@infrastructure/http/article/articles';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {CUSTOMERS, Customers} from '@infrastructure/http/customer/customers';
import {SALES_ORDERS, SalesOrders} from '@infrastructure/http/sales-order/sales-orders';
import {OverviewListArticle} from '@presentation/pages/texfab/article/overview/overview-list-article';
import {SelectArticleComponent} from '@presentation/pages/texfab/sales-order/add/select-article/select-article.component';
import {SelectCustomerComponent} from '@presentation/pages/texfab/sales-order/add/select-customer/select-customer.component';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BackendLimitsConstants} from '@shared/constants/backend-limits.constants';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  canShowErrorForErrorCodeAndButtonForFormGroup,
  Conflict,
  ConflictsDialogComponent,
  ConflictsDialogData,
  DialogBuilderFactoryService,
  DialogComponentData,
  DialogType,
  EnumUtils,
  FormValidationHelper,
  HeaderAction,
  Priority,
  SaveType,
  skeletonViewAnimation,
  ToastService,
  TranslateService
} from '@vdw/angular-component-library';
import {isEqual, isNil, some} from 'lodash-es';
import {Observable} from 'rxjs';
import {finalize, switchMap, takeUntil} from 'rxjs/operators';
import {NavigationSalesOrderData} from './navigation-sales-order-data.interface';

@Component({
  templateUrl: './add-sales-order-page.component.html',
  styleUrls: ['./add-sales-order-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .mat-card-header, .ag-root, .button-skeleton-wrapper')]
})
export class AddSalesOrderPageComponent extends BaseComponent implements OnInit, AfterViewChecked {
  private static readonly salesOrderTranslationKey = 'SALES_ORDERS.SALES_ORDER';
  public readonly CUSTOMER_BASE_ROUTER_LINK = RouteUtils.paths.texFab.customer.editCustomer.absolutePath;
  public readonly ARTICLE_BASE_ROUTER_LINK = RouteUtils.paths.texFab.article.editArticle.absolutePath;
  public addSalesOrderForm: FormGroup<{
    orderNumber: FormControl<number>;
    customer: FormControl<CustomerForSalesOrder>;
    desiredDate: FormControl<Date>;
    priority: FormControl<Priority>;
    orderLines: FormArray<OrderLineFormGroup>;
    status: FormControl<SalesOrderStatus>;
    paymentStatus?: FormControl<PaymentStatus>;
    amount?: FormControl<number>;
    amountProduced?: FormControl<number>;
  }>;

  public isLoadingSalesOrder = false;
  public priorities: string[] = EnumUtils.getEnumNames(Priority);
  public paymentStatuses: string[] = EnumUtils.getEnumNames(PaymentStatus);
  public salesOrderToSave: SalesOrder;
  public headerActions: HeaderAction[];
  public readonly int32MaximumValue = BackendLimitsConstants.INT32_MAX;
  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_SALES_ORDER;
  private readonly urlToEditSalesOrder = RouteUtils.paths.texFab.salesOrder.editSalesOrder.path;
  private readonly urlToDuplicateSalesOrder = RouteUtils.paths.texFab.salesOrder.duplicateSalesOrder.path;
  private readonly urlToSalesOrderOverview = RouteUtils.paths.texFab.salesOrder.absolutePath;
  private readonly BASIC_DIALOG_PANEL_CLASS = 'basic-dialog-panel';
  private salesOrderEditPermission: Permission = LastModifiedCardUtils.getPermissionToModifyItems('salesOrder');
  private currentSubscription: ProfileSubscription;
  private saveButtonTouched = false;
  private selectedOrderlineIndex: number;

  public constructor(
    @Inject(SALES_ORDERS) private readonly salesOrders: SalesOrders,
    @Inject(CUSTOMERS) private readonly customers: Customers,
    @Inject(ARTICLES) private readonly articles: Articles,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService,
    private readonly toastService: ToastService,
    private readonly navigationHelperService: NavigationHelperService<NavigationNewItemData & DialogComponentData>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();

    this.initializeAddSalesOrderFormFields();
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationSalesOrderData>({salesOrder: this.getCurrentSalesOrder(), selectedOrderlineIndex: this.selectedOrderlineIndex});
  }

  public isEditingSalesOrder(): boolean {
    return isEqual(this.activatedRoute.snapshot.routeConfig.path, this.urlToEditSalesOrder);
  }

  public getActionText(): string {
    const title = this.isEditingSalesOrder() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    return this.translate.instant(title, {object: (this.translate.instant(AddSalesOrderPageComponent.salesOrderTranslationKey, {count: 1}) as string).toLowerCase()});
  }

  public canShowErrorForFormControl(errorCode: string, formControlName: string, formGroup?: FormGroup): boolean {
    return canShowErrorForErrorCodeAndButtonForFormGroup(errorCode, formControlName, !isNil(formGroup) ? formGroup : this.addSalesOrderForm);
  }

  public isSalesOrderBeingUsed(): boolean {
    return this.salesOrderToSave && this.salesOrderToSave.used && !this.isDuplicatingSalesOrder() && this.hasEditPermission();
  }

  public openConflictsDialog(): void {
    this.salesOrders
      .getConflicts(this.salesOrderToSave.id)
      .pipe(
        switchMap((conflicts: Conflict[]) => {
          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass(this.BASIC_DIALOG_PANEL_CLASS)
            .withWidth('500px')
            .withHeight('auto')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createInUseData('SALES_ORDERS.SALES_ORDER', this.salesOrderToSave.orderNumber.toString(), conflicts, ConflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();
  }

  public selectCustomer(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectCustomerComponent)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((customer: Customer) => {
        if (!isNil(customer)) {
          this.addSalesOrderForm.get('customer').setValue(CustomerForSalesOrder.fromCustomer(customer));
        }
      });
  }

  public getChosenCustomer(): CustomerForSalesOrder {
    return this.addSalesOrderForm.get('customer').value;
  }

  public getEarliestPossibleDueDate(): Date {
    return new Date();
  }

  public getOrderLines(): OrderLineFormGroup[] {
    return this.getOrderLinesFormArray().controls;
  }

  public selectArticleForOrderLine(orderLine: FormGroup, orderLineIndex: number): void {
    this.selectedOrderlineIndex = orderLineIndex;

    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectArticleComponent)
      .subscribe((overviewListArticle: OverviewListArticle) => {
        this.selectedOrderlineIndex = null;
        if (!isNil(overviewListArticle)) {
          orderLine.get('article').setValue(ArticleForSalesOrderLine.fromOverviewListArticle(overviewListArticle));
        }
      });
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = some(this.addSalesOrderForm.controls, (control: FormControl) => control.invalid && control.touched);
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }

    return isFormInvalid && this.saveButtonTouched;
  }

  public hasAddedToOrderbook(): boolean {
    return this.salesOrderToSave.status === SalesOrderStatus.ADDED_TO_ORDERBOOK;
  }

  public cancel(): void {
    this.router.navigateByUrl(this.urlToSalesOrderOverview);
  }

  public saveSalesOrder(saveType: SaveType): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForms([this.addSalesOrderForm], this.document);

    if (isValid) {
      const salesOrderToSave: SalesOrder = this.getCurrentSalesOrder();
      this.saving = true;
      const request: Observable<void | number> = this.isEditingSalesOrder() ? this.salesOrders.update(salesOrderToSave) : this.salesOrders.save(salesOrderToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) =>
          this.textileService.navigateAndShowToast(
            saveType,
            TextileDataType.SALES_ORDER,
            AddSalesOrderPageComponent.salesOrderTranslationKey,
            this.isEditingSalesOrder(),
            salesOrderToSave.orderNumber.toString(),
            id
          ),
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError(this.isEditingSalesOrder() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public deleteSalesOrder(): void {
    this.textileService.removeConfirmation(this.salesOrderToSave, TextileDataType.SALES_ORDER, false, null, this.salesOrders);
  }

  public duplicateSalesOrder(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.salesOrder.duplicateSalesOrder.absolutePath.replace(':id', this.salesOrderToSave.id.toString()));
  }

  public hasEditPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.salesOrderEditPermission);
  }

  public hasMoreThanOneOrderLine(): boolean {
    return this.getOrderLines().length > 1;
  }

  public removeOrderLine(orderLineIndex: number): void {
    this.getOrderLinesFormArray().removeAt(orderLineIndex);
  }

  public addNewOrderLine(): void {
    const orderLines = this.getOrderLinesFormArray();
    const orderLineFormGroup = this.formBuilder.group({
      id: [null],
      article: [{value: null, disabled: !this.hasEditPermission() || this.isSalesOrderBeingUsed()}, Validators.required],
      amount: [1, [Validators.required, Validators.min(1)]],
      amountPlanned: [{value: 0, disabled: true}, Validators.required],
      amountLeft: [{value: 1, disabled: true}, Validators.required],
      amountProduced: [{value: 0, disabled: true}]
    });

    orderLineFormGroup
      .get('amount')
      .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((amount: number) => {
        orderLineFormGroup.get('amountLeft').patchValue(amount);
      });

    orderLines.push(orderLineFormGroup);
  }

  private getOrderLinesFormArray(): FormArray<OrderLineFormGroup> {
    return this.addSalesOrderForm.controls.orderLines;
  }

  private isDuplicatingSalesOrder(): boolean {
    return isEqual(this.activatedRoute.snapshot.routeConfig.path, this.urlToDuplicateSalesOrder);
  }

  private getCurrentSalesOrder(): SalesOrder {
    const orderNumber: number = this.addSalesOrderForm.get('orderNumber').value;
    const customer: CustomerForSalesOrder = this.addSalesOrderForm.get('customer').value;
    const desiredDate: Date = this.addSalesOrderForm.get('desiredDate').value;
    const priority: Priority = this.addSalesOrderForm.get('priority').value;
    const paymentStatus: PaymentStatus = this.isEditingSalesOrder() ? this.addSalesOrderForm.get('paymentStatus').value : PaymentStatus.OPEN;
    const status: SalesOrderStatus = this.addSalesOrderForm.get('status').value;

    const orderLines: OrderLineForSalesOrder[] = this.getOrderLines().map((orderLineGroup: OrderLineFormGroup) => {
      return new OrderLineForSalesOrder(
        orderLineGroup.get('id').value,
        orderLineGroup.get('article').value,
        orderLineGroup.get('amount').value,
        orderLineGroup.get('amountPlanned').value,
        orderLineGroup.get('amountLeft').value,
        orderLineGroup.get('amountProduced').value
      );
    });

    return new SalesOrder(this.salesOrderToSave.id, orderNumber, customer, desiredDate, priority, paymentStatus, orderLines, status);
  }

  private initializeAddSalesOrderFormFields(): void {
    this.setAddSalesOrderForm();

    const emptySalesOrderState = {salesOrder: null, selectedOrderlineIndex: null} as NavigationSalesOrderData;
    const salesOrderState = this.navigationHelperService.getPartialState<NavigationSalesOrderData>(Object.keys(emptySalesOrderState));
    if (!AssertionUtils.isNullOrUndefined(salesOrderState)) {
      this.salesOrderToSave = salesOrderState.salesOrder;
      this.setAddSalesOrderFormValues();
    } else if (this.isEditingSalesOrder() || this.isDuplicatingSalesOrder()) {
      const urlParams: Params = this.activatedRoute.snapshot.params;

      this.salesOrderToSave = SalesOrderStubFactory.getRandomSalesOrderWithId(1);
      this.setAddSalesOrderFormValues();

      this.isLoadingSalesOrder = true;

      this.salesOrders
        .getById(Number(urlParams.id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (salesOrder: SalesOrder) => {
            this.salesOrderToSave = salesOrder;
            this.isLoadingSalesOrder = false;
            this.setAddSalesOrderFormValues();
          },
          error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.urlToSalesOrderOverview)
        });
    } else {
      this.salesOrderToSave = SalesOrder.createEmptySalesOrder();
      this.setAddSalesOrderFormValues();
    }

    const orderLine = this.getOrderLines()[salesOrderState?.selectedOrderlineIndex];
    const getFunctionsBasedOnDialogComponent = new Map<any, NavigationFunctionData>([
      [SelectCustomerComponent, {loadNewItem: ({newItemId}: NavigationNewItemData): void => this.setNewCustomer(newItemId), reopenDialog: (): void => this.selectCustomer()}],
      [
        SelectArticleComponent,
        {
          loadNewItem: ({newItemId}: NavigationNewItemData): void => this.setNewArticle(newItemId, orderLine),
          reopenDialog: (): void => this.selectArticleForOrderLine(orderLine, salesOrderState.selectedOrderlineIndex)
        }
      ]
    ]);

    this.navigationHelperService.setNewItemOrReopenDialogIfPresent(
      ({newItemId}: NavigationNewItemData, {dialogComponent}: DialogComponentData) => getFunctionsBasedOnDialogComponent.get(dialogComponent).loadNewItem({newItemId}),
      ({dialogComponent}: DialogComponentData) => getFunctionsBasedOnDialogComponent.get(dialogComponent).reopenDialog()
    );
  }

  private setAddSalesOrderForm(): void {
    this.addSalesOrderForm = this.formBuilder.group({
      orderNumber: this.formBuilder.control(
        {value: null, disabled: this.isEditingSalesOrder()},
        [Validators.required, Validators.max(this.int32MaximumValue)],
        AsyncUniqueValidator.createValidator(this.salesOrders, null)
      ),
      customer: this.formBuilder.control({value: null, disabled: !this.hasEditPermission() || this.isSalesOrderBeingUsed()}, Validators.required),
      desiredDate: this.formBuilder.control({value: null, disabled: !this.hasEditPermission() || this.isSalesOrderBeingUsed()}, Validators.required),
      priority: this.formBuilder.control({value: null, disabled: !this.hasEditPermission() || this.isSalesOrderBeingUsed()}, Validators.required),
      orderLines: this.formBuilder.array([
        this.formBuilder.group({
          id: this.formBuilder.control(null),
          article: this.formBuilder.control(null),
          amount: this.formBuilder.control(null),
          amountPlanned: this.formBuilder.control(null),
          amountLeft: this.formBuilder.control(null),
          amountProduced: this.formBuilder.control(null)
        })
      ]),
      status: this.formBuilder.control({value: null, disabled: !this.hasEditPermission() || this.isSalesOrderBeingUsed()})
    });

    this.addSalesOrderForm.controls.orderLines.clear();
    this.headerActions = [];
  }

  private setAddSalesOrderFormValues(): void {
    this.addSalesOrderForm.patchValue({
      orderNumber: this.isDuplicatingSalesOrder() ? null : this.salesOrderToSave.orderNumber,
      customer: this.salesOrderToSave.customer,
      desiredDate: this.salesOrderToSave.desiredDate,
      priority: this.salesOrderToSave.priority,
      orderLines: [],
      status: null
    });
    this.addSalesOrderForm.controls.orderLines.clear();
    this.headerActions = [];

    this.salesOrderToSave.orderLines.forEach((orderLine: OrderLineForSalesOrder) => {
      const orderLineFormGroup: OrderLineFormGroup = this.formBuilder.group({
        id: [{value: orderLine.id, disabled: this.isSalesOrderBeingUsed()}],
        article: [{value: orderLine.article, disabled: this.isSalesOrderBeingUsed()}, Validators.required],
        amount: [{value: orderLine.amount, disabled: this.hasAddedToOrderbook() || this.isSalesOrderBeingUsed()}, Validators.required],
        amountPlanned: [{value: orderLine.amountPlanned, disabled: true}],
        amountLeft: [{value: orderLine.amountLeft, disabled: true}],
        amountProduced: [{value: orderLine.amountProduced, disabled: true}]
      });

      orderLineFormGroup
        .get('amount')
        .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((amount: number) => {
          orderLineFormGroup.get('amountLeft').patchValue(amount);
        });

      this.addSalesOrderForm.controls.orderLines.push(orderLineFormGroup);
    });

    this.addSalesOrderForm.controls.orderNumber.addAsyncValidators(AsyncUniqueValidator.createValidator(this.salesOrders, this.isEditingSalesOrder() ? this.salesOrderToSave.orderNumber : null));
    this.addSalesOrderForm.controls.orderNumber.updateValueAndValidity();
    if (this.isEditingSalesOrder()) {
      this.addSalesOrderForm.controls.orderNumber.disable();
    } else {
      this.addSalesOrderForm.controls.orderNumber.enable();
    }

    if (!this.hasEditPermission() || this.isSalesOrderBeingUsed()) {
      this.addSalesOrderForm.controls.customer.disable();
    } else {
      this.addSalesOrderForm.controls.customer.enable();
    }

    if (this.isEditingSalesOrder()) {
      this.addSalesOrderForm.addControl('paymentStatus', new FormControl({value: this.salesOrderToSave.paymentStatus, disabled: this.isSalesOrderBeingUsed()}));
      this.addSalesOrderForm.addControl('amount', new FormControl({value: this.salesOrderToSave.getTotalAmountRequested(), disabled: true}));
      this.addSalesOrderForm.addControl('amountProduced', new FormControl({value: this.salesOrderToSave.getTotalAmountProduced(), disabled: true}));
    } else if (!this.isDuplicatingSalesOrder() && this.salesOrderToSave.orderLines.length === 0) {
      this.addNewOrderLine();
    }

    if (this.isSalesOrderBeingUsed() || !this.hasEditPermission()) {
      this.addSalesOrderForm.controls.desiredDate.disable();
      this.addSalesOrderForm.controls.priority.disable();
      this.addSalesOrderForm.controls.status.disable();
      this.addSalesOrderForm.controls.paymentStatus?.disable();
    } else {
      this.addSalesOrderForm.controls.desiredDate.enable();
      this.addSalesOrderForm.controls.priority.enable();
      this.addSalesOrderForm.controls.status.enable();
      this.addSalesOrderForm.controls.paymentStatus?.enable();
    }

    this.headerActions = [
      {
        label: this.translate.instant('SALES_ORDERS.ADD_TO_ORDER_BOOK'),
        disabled: this.salesOrderToSave.status === SalesOrderStatus.ADDED_TO_ORDERBOOK,
        onClick: (): void => this.addToOrderBook()
      }
    ];
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {
        object: this.translate.instant(AddSalesOrderPageComponent.salesOrderTranslationKey, {
          count: 1
        })
      }),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }

  private addToOrderBook(): void {
    if (this.salesOrderToSave) {
      this.salesOrders
        .copyToOrderbook(this.salesOrderToSave.id)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: () => {
            this.toastService.showInfo({
              tapToDismiss: false,
              timeOut: 2000,
              message: this.translate.instant('SALES_ORDERS.ADDED')
            });
            this.initializeAddSalesOrderFormFields();
          },
          error: () => {
            this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
              titleText: this.translate.instant('SALES_ORDERS.ADD_TO_ORDER_BOOK'),
              messageText: this.translate.instant('SALES_ORDERS.ADD_TO_ORDER_BOOK_ERROR', {salesOrderName: this.salesOrderToSave.orderNumber}),
              type: DialogType.INFORMATION
            });
          }
        });
    }
  }

  private setNewCustomer(id: number): void {
    this.customers
      .getById(id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((customer: Customer) => this.addSalesOrderForm.get('customer').setValue(CustomerForSalesOrder.fromCustomer(customer)));
  }

  private setNewArticle(id: number, orderLine: FormGroup): void {
    this.articles
      .getById(id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((article: Article) =>
        orderLine.get('article').setValue(
          new ArticleForSalesOrderLine(article.id, article.name, {
            shape: article.design.shape,
            repeated: article.design.repeated
          })
        )
      );
  }
}

type OrderLineFormGroup = FormGroup<{
  id: FormControl<number>;
  article: FormControl<ArticleForSalesOrderLine>;
  amount: FormControl<number>;
  amountPlanned: FormControl<number>;
  amountLeft: FormControl<number>;
  amountProduced: FormControl<number>;
}>;
