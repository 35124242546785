import {Component, Input} from '@angular/core';
import {ProductionOrderCarpetForPlanningOrder} from '@domain/planning/carpet/production-order-carpet-for-planning-order';
import {PlanningOrderData} from '@domain/planning/planning-order-data';
import {ProductionOrderTuftingForPlanningOrder} from '@domain/planning/tufting/production-order-tufting-for-planning-order';
import {ProductionOrderWeavingForPlanningOrder} from '@domain/planning/weaving/production-order-weaving-for-planning-order';
import {DateTimeFormat, DateTimePipe, TranslateService} from '@vdw/angular-component-library';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent {
  @Input() public orderData: PlanningOrderData;

  public constructor(
    private readonly translate: TranslateService,
    private readonly dateTimePipe: DateTimePipe
  ) {}

  public get productionOrderCarpet(): ProductionOrderCarpetForPlanningOrder {
    return this.orderData instanceof ProductionOrderCarpetForPlanningOrder && this.orderData;
  }

  public get productionOrderWeaving(): ProductionOrderWeavingForPlanningOrder {
    return this.orderData instanceof ProductionOrderWeavingForPlanningOrder && this.orderData;
  }

  public get productionOrderTufting(): ProductionOrderTuftingForPlanningOrder {
    return this.orderData instanceof ProductionOrderTuftingForPlanningOrder && this.orderData;
  }

  public get yieldUnit(): string {
    if (this.productionOrderCarpet) {
      return 'GENERAL.UNIT.PICKS';
    }
    if (this.productionOrderWeaving || this.productionOrderTufting) {
      return 'GENERAL.UNIT.METER';
    }
    return '';
  }

  public get yieldUnitShouldHaveASpace(): boolean {
    return !!this.productionOrderCarpet;
  }

  public get plannedAmountText(): string {
    return `${this.orderData.yield.planned}${this.yieldUnitShouldHaveASpace ? ' ' : ''}${this.translate.instant(this.yieldUnit)}`;
  }

  public get dueDateAndPlannedAmountText(): string {
    const dueDateText = this.translate.instant('PLANNING.NEEDS_ATTENTION.DUE_AT', this.dateTimePipe.transform(this.orderData.dueDate, DateTimeFormat.DATE_SHORT));
    return `${dueDateText} - ${this.plannedAmountText}`;
  }

  public get ellipsisLabelText(): string {
    if (this.productionOrderCarpet) {
      return `${this.productionOrderCarpet.machineQuality.technicalName}/${this.productionOrderCarpet.creel.name}`;
    }
    if (this.productionOrderWeaving) {
      return this.productionOrderWeaving.weaveProduct.name;
    }
    return this.productionOrderTufting.tuftProduct.name;
  }
}
