<div class="flex-row gap-16">
  <mat-icon class="message-container__icon flex-column" class="icon-36" [svgIcon]="messages[0].type"></mat-icon>
  <div class="message-container__container flex-column flex-center-start gap-4">
    @if (showTitle) {
      <div class="full-width flex-row flex-space-between gap-16">
        <div class="flex-1 overflow-hidden">
          @if (title) {
            <h4 class="message-container__title font-weight-semibold flex-1" vdw-ellipsis-label [text]="title" matTooltipPosition="above"></h4>
          } @else {
            <h4 class="message-container__title font-weight-semibold flex-1" vdw-ellipsis-label [text]="messages[0].title" matTooltipPosition="above"></h4>
          }
        </div>
        @if (showTimestamp) {
          <p class="b2">{{ getCurrentDateForSubscriptionTimezone(messages[0].timestamp) }}</p>
        }
      </div>
    }

    @if (!onlyShowTitle) {
      @if (canShowMessageList()) {
        <ul class="message-list no-margin full-width flex-column gap-4">
          @for (message of messages; track $index) {
            <li>
              <div class="message-list-item flex-row gap-16">
                <p class="b1 flex-1">{{ message.description }}</p>
                @if (canShowTimestampForEachMessage()) {
                  <p class="b1">{{ getCurrentDateForSubscriptionTimezone(message.timestamp) }}</p>
                }
              </div>
            </li>
          }
        </ul>
      } @else {
        <div class="message-list-item full-width flex-row gap-16">
          <p class="b1 flex-1">{{ messages[0].description }}</p>
          @if (canShowTimestampForEachMessage()) {
            <p class="b1">{{ getCurrentDateForSubscriptionTimezone(messages[0].timestamp) }}</p>
          }
        </div>
      }
    }
  </div>
</div>
