<div class="full-width full-height bms-theme flex-column p-24">
  @if (showHeader) {
    <div class="header">
      @if (canShowBackButton()) {
        <button
          mat-icon-button
          class="mat-icon-action-button-lg-with-border back-button"
          (click)="navigationHelper.navigateBack()"
          [matTooltip]="'back'"
          matTooltipPosition="above"
          matTooltipClass="bms-theme"
        >
          <mat-icon class="back-icon" svgIcon="solid-arrow-back"></mat-icon>
        </button>
      }
      <div class="font-weight-semibold header-title">{{ getContentSwitcherHeaderTitle() }}</div>
    </div>
  }

  <div class="full-width full-height">
    <ng-container #contentSwitcherContainer></ng-container>
  </div>
</div>
