import {EventEmitter, Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteData} from '@application/helper/routing/route-data.interface';
import {Permission} from '@domain/profile/permission.enum';
import {PropertyValue} from '@domain/property-value';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {HttpPlasticProductsService} from '@infrastructure/http/plastic-product/http-plastic-products.service';
import {
  AssertionUtils,
  ContentSwitcherDialogService,
  CrudGridContextService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  DialogBuilderFactoryService,
  DialogType,
  GridButtonClickedEvent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {TextileService} from '../textile-data-overview/textile.service';
import {TextileDataType} from '../textile-data-type.enum';
import {PlasticProductOverviewService} from './overview/plastic-product-overview.service';

@Injectable()
export class PlasticProductSelectionService {
  private config: CrudOverviewDataConfig<PlasticProduct>;
  private readonly reloadData = new EventEmitter<PropertyValue[]>();

  private readonly routes = [
    NavigationUtils.getNavigationRoute(NavigationId.PLASTIC_PRODUCT_MODULE),
    NavigationUtils.getNavigationRoute(NavigationId.PLASTIC_PRODUCT),
    NavigationUtils.getNavigationRoute(NavigationId.ADD_PLASTIC_PRODUCT),
    NavigationUtils.getNavigationRoute(NavigationId.EDIT_PLASTIC_PRODUCT),
    NavigationUtils.getNavigationRoute(NavigationId.DUPLICATE_PLASTIC_PRODUCT)
  ];

  public constructor(
    private readonly injector: Injector,
    private readonly plasticProducts: HttpPlasticProductsService,
    private readonly translate: TranslateService,
    private readonly overview: PlasticProductOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<PlasticProduct>,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService,
    private readonly plasticProductService: HttpPlasticProductsService,
    private readonly crudGridContextService: CrudGridContextService
  ) {}

  public init(enableMultiDelete: boolean): void {
    const addRoute = NavigationUtils.getNavigationRoute(NavigationId.ADD_PLASTIC_PRODUCT);

    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT')
      .withRowData(this.plasticProducts.getAll())
      .withNewClicked((): void =>
        this.contentSwitcher.navigateForward(
          {
            key: NavigationId.ADD_PLASTIC_PRODUCT.toString(),
            routeData: addRoute.data as RouteData,
            data: null,
            component: addRoute.component,
            entityName: 'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT'
          },
          {}
        )
      )
      .withContentSwitcher()
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsWithRouting(
          this.routes,
          Permission.TEXSTYLE_PLASTIC_PRODUCT_EDIT,
          'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT',
          ({gridApi, rowNode}: GridButtonClickedEvent) => {
            const data = AssertionUtils.isNullOrUndefined(rowNode) ? (gridApi?.getSelectedRows()[0] as PlasticProduct) : (rowNode.data as PlasticProduct);

            if (!AssertionUtils.isEmpty(this.config.selectedObjects) && data.id === this.config.selectedObjects[0]?.id) {
              this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
                titleText: this.translate.instant('CONFLICTS.DIALOG.IN_USE', {object: data.name}),
                messageText: this.translate.instant('CONFLICTS.DIALOG.DESELECT'),
                type: DialogType.DELETE
              });
              return;
            }

            this.textileService.removeConfirmation(
              AssertionUtils.isNullOrUndefined(rowNode) ? gridApi?.getSelectedRows()[0] : rowNode.data,
              TextileDataType.PLASTIC_PRODUCT,
              false,
              null,
              this.plasticProductService,
              null,
              null,
              this.reloadData,
              null,
              false,
              null,
              null,
              null,
              gridApi,
              true
            );
          }
        )
      )
      .withGridOptions(this.overview.getColumnDefs(false), GridIdentifier.PLASTIC_PRODUCT_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        builder.withRowSelection(enableMultiDelete, true, true).build()
      )
      .build();
  }

  public openContentSwitcherDialog(inputValue: PlasticProduct | PlasticProduct[], data: any): Observable<PlasticProduct[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }
    this.config.selectedObjects = Array.isArray(inputValue) ? inputValue : [inputValue];

    const navigationRoute = NavigationUtils.getNavigationRoute(NavigationId.PLASTIC_PRODUCT);
    const entry = {key: NavigationId.PLASTIC_PRODUCT.toString(), component: navigationRoute.component, data: null, routeData: navigationRoute.data as RouteData};

    this.contentSwitcher.navigateForward(entry, {config: this.config});
    return this.builderFactoryService.openContentSwitcherDialog();
  }

  public setSelected(inputValue: PlasticProduct): void {
    this.config.selectedObjects = Array.isArray(this.config.selectedObjects) ? [inputValue, ...this.config.selectedObjects] : [inputValue];
  }
}
