import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AG_GRID_LICENSE_KEY} from '@application/configuration/license-keys';
import {environment} from '@environments/environment';
import {ContentSwitcherDialogPrototypeComponent, ContentSwitcherDialogPrototypeService} from '@vdw/angular-component-library';
import {LicenseManager} from 'ag-grid-enterprise';
import {AppModule} from './app/app.module';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

platformBrowserDynamic()
  .bootstrapModule(AppModule, {ngZoneEventCoalescing: true, ngZoneRunCoalescing: true})
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }
    ContentSwitcherDialogPrototypeService.contentSwitcherDialogComponentType = ContentSwitcherDialogPrototypeComponent;
  })
  // eslint-disable-next-line no-console
  .catch((err: Error) => console.log(err));
