import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {CustomSettings} from '@domain/custom-settings/custom-settings';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {PlasticProductionOrder} from '@domain/texfab/plastic-production-order/plastic-production-order';
import {map, Observable} from 'rxjs';
import {CustomSettingsRepository} from '../custom-settings-repository';

@Injectable({providedIn: 'root'})
export class HttpPlasticProductionOrdersCustomSettingsService extends CustomSettingsRepository<CustomSettings, PlasticProductionOrder> {
  public constructor(
    httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {
    super(httpClient, `${backendConfiguration.getProductionOrderEndpoint()}production-orders/plastic/custom-settings`);
  }

  public override getNameGenerationProperties(): Observable<NameGeneratorProperty[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getProductionOrderEndpoint(2)}production-orders/plastic/custom-settings/name-generation-properties`)
      .pipe(map((json: any[]) => json.map((jsonElement: any) => NameGeneratorProperty.fromJSON(jsonElement))));
  }
}
