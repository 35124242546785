import {EventEmitter, Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {ContentSwitcherUtils} from '@application/helper/utilities/content-switcher/content-switcher-utils';
import {IdName} from '@domain/id-name';
import {Permission} from '@domain/profile/permission.enum';
import {PropertyValue} from '@domain/property-value';
import {HttpStockLocationService} from '@infrastructure/http/stock-location/http-stock-location.service';
import {HttpToolsService} from '@infrastructure/http/tool/http-tools.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  AssertionUtils,
  ContentSwitcherDialogService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  CrudOverviewDataPageComponent,
  DialogBuilderFactoryService,
  DialogType,
  GridButtonClickedEvent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {StockLocationOverviewService} from './stock-location-overview.service';

@Injectable()
export class StockLocationSelectionService {
  private config: CrudOverviewDataConfig<IdName>;
  private readonly reloadData = new EventEmitter<PropertyValue[]>();

  public constructor(
    private readonly injector: Injector,
    private readonly tools: HttpToolsService,
    private readonly stockLocationsService: HttpStockLocationService,
    private readonly translate: TranslateService,
    private readonly overview: StockLocationOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<IdName>,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService
  ) {
    this.init();
  }

  public init(): void {
    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('UTILITIES.TOOL.STOCK_LOCATION')
      .withRowData(this.tools.getStockLocations())
      .withContentSwitcher(ContentSwitcherUtils.getAddStockLocationEntry())
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsWithEntries(
          ContentSwitcherUtils.getEditStockLocationEntry(),
          null,
          Permission.TEXSTYLE_STOCK_LOCATION_EDIT,
          ({gridApi, rowNode}: GridButtonClickedEvent) => {
            const data = AssertionUtils.isNullOrUndefined(rowNode) ? (gridApi?.getSelectedRows()[0] as IdName) : (rowNode.data as IdName);

            if (!AssertionUtils.isEmpty(this.config.selectedObjects) && data.id === this.config.selectedObjects[0]?.id) {
              this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
                titleText: this.translate.instant('CONFLICTS.DIALOG.IN_USE', {object: data.name}),
                messageText: this.translate.instant('CONFLICTS.DIALOG.DESELECT'),
                type: DialogType.DELETE
              });
              return;
            }

            this.textileService.removeConfirmation(
              AssertionUtils.isNullOrUndefined(rowNode) ? gridApi?.getSelectedRows()[0] : rowNode.data,
              TextileDataType.STOCK_LOCATION,
              false,
              null,
              this.stockLocationsService,
              null,
              null,
              this.reloadData,
              null,
              false,
              null,
              null,
              null,
              gridApi,
              true
            );
          }
        )
      )
      .withGridOptions(this.overview.getColumnDefs(), GridIdentifier.STOCK_LOCATION_OVERVIEW, null, (builder: GridOptionsBuilder) => {
        builder = this.overview.getDefaultGridOptionsBuilder(builder);

        return builder.withRowSelection(false, false, true, false).build();
      })
      .build();
  }

  public openContentSwitcherDialog(inputValue: IdName, data: any): Observable<IdName[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }
    this.config.selectedObjects = Array.isArray(inputValue) ? inputValue : [inputValue];

    const entry = {key: 'stock-location', component: CrudOverviewDataPageComponent, data: null};

    this.contentSwitcher.navigateForward(entry, {config: this.config});

    return this.builderFactoryService.openContentSwitcherDialog();
  }

  public setSelected(inputValue: IdName): void {
    this.config.selectedObjects = [inputValue];
  }
}
