import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  Self,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatOption} from '@angular/material/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {MatSelect} from '@angular/material/select';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs';
import {Color} from '../../common/data/color';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {StringUtils} from '../../common/utils/string-utils';
import {TranslateService} from '../../translation/translate.service';
import {MatFormFieldControlHelper} from '../helper/form-field-control-helper';
import {InputChipComponent} from '../input-chip/input-chip.component';
import {CurrentSelection} from './current-selection.interface';

@Component({
  selector: 'vdw-object-selection',
  templateUrl: './object-selection.component.html',
  styleUrls: ['./object-selection.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: ObjectSelectionComponent}]
})
export class ObjectSelectionComponent extends MatFormFieldControlHelper<any> implements OnInit, DoCheck, AfterViewInit, OnDestroy, OnChanges {
  @ViewChildren('visibleInputChips') public visibleInputChipComponents: QueryList<InputChipComponent>;
  @ViewChild('inputField') public inputFieldElement: ElementRef;
  @ViewChild('overflowChipTrigger') public overflowChipTrigger: MatMenuTrigger;
  @ViewChild('overflowChips') public overflowChipsMenu: ElementRef<MatMenu>;
  @ViewChild('dropdownSelect') public formControl: MatSelect;
  @ViewChild('disabledDropdownTrigger') public disabledDropdownTrigger: MatMenuTrigger;
  @ViewChild('disabledDropdown') public disabledDropdownMenu: ElementRef<MatMenu>;

  @Input() public canShowBMSTheme = false;
  @Input() public baseRouterLink: string;
  @Input() public objectName: string;
  @Input() public translationKey: string;
  @Input() public chipValueKey: string;
  @Input() public conditionalChipValueKey: string;
  @Input() public inProgress = false;
  @Input() public placeHolderText: string = null;
  @Input() public listOfOptions: any[];
  @Input() public isObject = true;
  @Input() public canShowMultiSelectPlaceHolder = true;
  @Input() public withDialogObjectSelection = false;
  @Input() public refreshObjectSelection: boolean = false;

  @Output() public selectClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clearClicked: EventEmitter<void> = new EventEmitter<void>();
  public static nextId = 0;

  public id = `vdw-object-selection-${ObjectSelectionComponent.nextId++}`;
  public multiSelect = false;
  public overflowAmount = 0;

  public inputChips: CurrentSelection[] = [];
  public filteredText = '';

  private readonly INPUT_FIELD_GAP_WIDTH = this.canShowBMSTheme ? 4 : 8;
  private readonly INPUT_FIELD_EDIT_BUTTON_WIDTH = this.canShowBMSTheme ? 16 : 40;
  private readonly INPUT_FIELD_CLOSE_ICON_WIDTH = this.canShowBMSTheme ? 16 : 24;
  private readonly INPUT_FIELD_OVERFLOW_BUTTON_WIDTH = this.canShowBMSTheme ? 24 : 31;
  private readonly INPUT_CHIP_OVERFLOW_MIN_WIDTH = 200;
  private readonly SELECT_TRANSLATION_KEY = 'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.SELECT';
  private readonly EDIT_TRANSLATION_KEY = 'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.EDIT';
  private readonly RESERVED_WIDTH_INSIDE_INPUT_FIELD = 2 * this.INPUT_FIELD_GAP_WIDTH + this.INPUT_FIELD_EDIT_BUTTON_WIDTH + this.INPUT_FIELD_CLOSE_ICON_WIDTH;

  private hasUniqueId = true;

  public constructor(
    @Optional() @Self() public readonly ngControl: NgControl,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    super(ngControl);
  }

  public ngOnInit(): void {
    super.init();

    this.setInputChips();
    this.ngControl?.valueChanges?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => {
      this.setInputChips();
      setTimeout(() => this.setVisibleInputChips());
    });
  }

  public ngDoCheck(): void {
    super.check();

    const PADDING_LEFT_AND_RIGHT = 8;
    const DISABLED_DROPDOWN_WIDTH = this.elementRef.nativeElement.clientWidth + PADDING_LEFT_AND_RIGHT + PADDING_LEFT_AND_RIGHT;
    const DISABLED_DROPDOWN_PARENT_ELEMENT = document.querySelector('.disabled-dropdown')?.parentElement;

    if (DISABLED_DROPDOWN_PARENT_ELEMENT) {
      this.renderer.setStyle(DISABLED_DROPDOWN_PARENT_ELEMENT, 'width', `${DISABLED_DROPDOWN_WIDTH}px`);
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.setVisibleInputChips());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setInputChips();
    if (('inProgress' in changes && !changes.inProgress.isFirstChange() && !this.inProgress) || this.refreshObjectSelection) {
      setTimeout(() => this.setVisibleInputChips());
    }
  }

  public ngOnDestroy(): void {
    super.destroy();
  }

  public isFiltered(value: any): boolean {
    const filteredOptions = this.listOfOptions?.filter((option: any) => (option.name ?? option).toLowerCase().includes(this.filteredText.toLowerCase()));
    return filteredOptions.find((option: any) => option.name === value.name);
  }

  public canClickInput(): boolean {
    return this.isEmpty() && !this.disabled;
  }

  public onInputClicked(): void {
    if (this.canClickInput()) {
      this.selectClicked.emit();
    }
  }

  public onInputClickedWithDialogObjectSelection(): void {
    if (!this.disabled) {
      this.selectClicked.emit();
    }
  }

  public onSelectClicked(event: Event): void {
    event.stopPropagation();
    this.selectClicked.emit();
  }

  public isEmpty(): boolean {
    return this.inputChips?.length < 1;
  }

  public getButtonValue(): string {
    return this.isEmpty() ? this.SELECT_TRANSLATION_KEY : this.EDIT_TRANSLATION_KEY;
  }

  public removeChip(id: number): void {
    this.overflowChipTrigger?.closeMenu();

    const newValue = this.ngControl.value.filter((value: any, index: number) => (this.hasUniqueId ? value.id : index) !== id);
    this.updateValue(newValue.length === 0 ? null : newValue);
    const removedOption = this.formControl?.options.find((item: MatOption, index: number) => (this.hasUniqueId ? item.value.id : index) === id);
    removedOption?.deselect();

    setTimeout(() => this.setVisibleInputChips());
  }

  public deleteSelection(event: Event): void {
    event.stopPropagation();
    this.overflowChipTrigger?.closeMenu();

    if (this.clearClicked.observed) {
      this.clearClicked.emit();
      this.ngControl?.control?.markAsTouched();
    } else {
      this.deleteValue();
      setTimeout(() => this.setVisibleInputChips());
    }
  }

  public deleteAllSelection(event: MouseEvent): void {
    event.stopPropagation();

    this.ngControl?.control?.reset();
    this.ngControl?.control?.markAsTouched();
    this.inputChips = [];

    if (this.clearClicked.observed) {
      this.clearClicked.emit();
    }
  }

  public getOverflowChips(): CurrentSelection[] {
    const overflowChips = this.visibleInputChipComponents?.filter((component: InputChipComponent) => (component.elementRef?.nativeElement as HTMLElement)?.classList?.contains('hidden'));
    return (
      overflowChips
        ?.map((_: InputChipComponent, index: number) => this.inputChips[this.inputChips.length - overflowChips.length + index])
        .filter((value: CurrentSelection) => !AssertionUtils.isNullOrUndefined(value)) ?? []
    );
  }

  public getPlaceholderText(): string {
    if (!AssertionUtils.isEmpty(this.placeHolderText)) {
      return this.placeHolderText;
    }

    return this.translate.instant(
      this.canShowBMSTheme && this.canShowMultiSelectPlaceHolder ? 'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.SELECT_ONE_OR_MORE_OBJECT' : 'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.SELECT_OBJECT',
      {
        object: this.objectName
      }
    );
  }

  public canShowIndeterminate(): boolean {
    const selectedOptionsLength = this.ngControl.value?.length;
    return selectedOptionsLength > 0 && selectedOptionsLength !== this.listOfOptions.length;
  }

  public canShowChecked(): boolean {
    const selectedOptionsLength = this.ngControl.value?.length;
    return selectedOptionsLength === this.listOfOptions.length;
  }

  public selectAll(matCheckboxChange: MatCheckboxChange): void {
    if (matCheckboxChange.checked) {
      this.formControl.options.forEach((item: MatOption) => item.select());
    } else {
      this.formControl.options.forEach((item: MatOption) => item.deselect());
    }
  }

  public openDetails(event: PointerEvent, value: any): void {
    event.preventDefault();
    event.stopPropagation();
    const url = this.getInputChipUrl(value);
    this.router.navigateByUrl(url);
  }

  public getTranslation(value: any): string {
    return this.setInputChipArray([value])[0].value;
  }

  public isColor(value: any): boolean {
    return value instanceof Color;
  }

  public resetFilterText(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.filteredText = '';
  }

  public canShowNoResult(): boolean {
    return !AssertionUtils.isEmpty(this.filteredText) && this.listOfOptions?.filter((option: any) => (option.name ?? option).toLowerCase().includes(this.filteredText.toLowerCase())).length === 0;
  }

  private setInputChips(): void {
    const formControlValue = this.ngControl?.value;
    if (Array.isArray(formControlValue) && formControlValue.length > 1) {
      this.multiSelect = true;
      this.inputChips = this.setInputChipArray(formControlValue);
    } else if (formControlValue) {
      this.inputChips = this.setInputChipArray(formControlValue.length <= 1 ? [...formControlValue] : [formControlValue]);
    } else {
      this.inputChips = [];
    }

    this.changeDetectorRef.detectChanges();
  }

  private setInputChipArray(array: any[]): CurrentSelection[] {
    const inputChips = array.map((value: any, index: number) => {
      this.hasUniqueId = !this.translationKey && !!value.id;
      const chipValueKey = this.chipValueKey;
      const conditionalChipValueKey = this.conditionalChipValueKey;
      const translationKey = this.translationKey;

      if (chipValueKey) {
        return this.setInputChipsForChipValueKey(chipValueKey, value, index);
      } else if (conditionalChipValueKey && AssertionUtils.isNullOrUndefined(value.name)) {
        return this.setInputChipsForChipValueKey(conditionalChipValueKey, value, index);
      } else if (translationKey) {
        return this.setInputChipsForTranslationKey(value, index);
      } else if (AssertionUtils.isNullOrUndefined(value.name)) {
        return this.setInputChipsForValue(value, index);
      }

      return this.setInputChipsForName(value, index);
    });

    const uniqueInputChips = this.getUniqueInputChips(inputChips);

    return this.hasUniqueId
      ? [...uniqueInputChips.sort((valueA: CurrentSelection, valueB: CurrentSelection) => StringUtils.stringEndingWithNumberComparator(valueA.value, valueB.value))]
      : uniqueInputChips;
  }

  private setInputChipsForChipValueKey(replacementKey: string, value: any, index: number): CurrentSelection {
    return {
      id: value.id || index,
      value: replacementKey.replace(/%\((.*?)\)/g, (_: string, placeholder: string) => value[placeholder]),
      url: this.getInputChipUrl(value),
      colorInHex: value instanceof Color ? value.hexadecimalColorCode : null
    } as CurrentSelection;
  }

  private setInputChipsForTranslationKey(value: any, index: number): CurrentSelection {
    return {
      id: index,
      value: this.translate.instant(`${this.translationKey}.${value}`),
      url: this.getInputChipUrl(value),
      colorInHex: value instanceof Color ? value.hexadecimalColorCode : null
    };
  }

  private setInputChipsForValue(value: any, index: number): CurrentSelection {
    return {
      id: value.id || index,
      value,
      url: this.getInputChipUrl(value),
      colorInHex: value instanceof Color ? value.hexadecimalColorCode : null
    };
  }

  private setInputChipsForName(value: any, index: number): CurrentSelection {
    return {
      id: value.id || index,
      value: value.name || value,
      url: this.getInputChipUrl(value),
      colorInHex: value instanceof Color ? value.hexadecimalColorCode : null
    };
  }

  private getUniqueInputChips(chips: CurrentSelection[]): CurrentSelection[] {
    return chips.reduce((previousValue: CurrentSelection[], currentValue: CurrentSelection) => {
      if (!previousValue.some((value: CurrentSelection) => value.id === currentValue.id)) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);
  }

  private setVisibleInputChips(): void {
    const availableWidthInputChips = this.inputFieldElement?.nativeElement.offsetWidth - this.RESERVED_WIDTH_INSIDE_INPUT_FIELD;

    this.overflowAmount = 0;
    let usedWidthByInputChips = 0;
    let index = 0;

    for (const component of this.visibleInputChipComponents) {
      const htmlComponent = component.elementRef?.nativeElement as HTMLElement;

      if (AssertionUtils.isNullOrUndefined(htmlComponent)) {
        continue;
      }

      htmlComponent.classList.remove('hidden');
      htmlComponent.style.width = 'fit-content';

      const chipWidth = htmlComponent.offsetWidth + 1;
      const overflowButtonWidth = index === this.visibleInputChipComponents.length - 1 ? 0 : this.INPUT_FIELD_OVERFLOW_BUTTON_WIDTH + this.INPUT_FIELD_GAP_WIDTH;
      const availableWidth = availableWidthInputChips - usedWidthByInputChips - overflowButtonWidth;

      if (availableWidth > chipWidth) {
        usedWidthByInputChips += chipWidth + this.INPUT_FIELD_GAP_WIDTH;
      } else if (availableWidth > this.INPUT_CHIP_OVERFLOW_MIN_WIDTH || this.inputChips.length === 1) {
        htmlComponent.style.width = this.inputChips.length === 1 ? '100%' : `${this.INPUT_CHIP_OVERFLOW_MIN_WIDTH}px`;
        usedWidthByInputChips += this.INPUT_CHIP_OVERFLOW_MIN_WIDTH + this.INPUT_FIELD_GAP_WIDTH;
      } else if (index === 0) {
        htmlComponent.style.width = `${availableWidth}px`;
        usedWidthByInputChips += availableWidth + this.INPUT_FIELD_GAP_WIDTH;
      } else {
        htmlComponent.classList.add('hidden');
        usedWidthByInputChips += chipWidth + this.INPUT_FIELD_GAP_WIDTH;
        this.overflowAmount += 1;
      }

      index = index + 1;
    }
  }

  private getInputChipUrl(value: any): string {
    if (value.parentId) {
      this.baseRouterLink = this.baseRouterLink.replace(':id', ':parentId');
    }

    return this.baseRouterLink
      ? this.baseRouterLink.replace(/:(\w+)/g, (_: string, key: string) => {
          if (key === 'machineType') {
            if (!AssertionUtils.isNullOrUndefined(value['machineType'])) {
              return value['machineType'].replace('_', '-').toLowerCase();
            } else if (!AssertionUtils.isNullOrUndefined(value['type'])) {
              return value['type'].replace('_', '-').toLowerCase();
            }
          }

          return value[key];
        })
      : '';
  }
}
