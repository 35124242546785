import {addRawMaterialEntry, editRawMaterialEntry} from '@application/helper/textile-data/raw-material/add-raw-material-entry';
import {ContentSwitcherEntry} from '@vdw/angular-component-library';
import {addStockLocationEntry, editStockLocationEntry} from '../stock-location/add-stock-location-entry';

export class ContentSwitcherUtils {
  private static readonly componentEntries = [addStockLocationEntry, editStockLocationEntry, addRawMaterialEntry, editRawMaterialEntry] as ContentSwitcherEntry[];

  public static getAddStockLocationEntry(): ContentSwitcherEntry {
    return this.componentEntries.find((entry: ContentSwitcherEntry) => entry.key === 'stock-location/add');
  }

  public static getEditStockLocationEntry(): ContentSwitcherEntry {
    return this.componentEntries.find((entry: ContentSwitcherEntry) => entry.key === 'stock-location/edit/:id');
  }

  public static getAddRawMaterialEntry(): ContentSwitcherEntry {
    return this.componentEntries.find((entry: ContentSwitcherEntry) => entry.key === 'raw-material/add');
  }

  public static getEditRawMaterialEntry(): ContentSwitcherEntry {
    return this.componentEntries.find((entry: ContentSwitcherEntry) => entry.key === 'raw-material/edit/:id');
  }
}
