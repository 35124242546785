import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {LocalStorageService} from '../../custom-services/local-storage.service/local-storage.service';
import {HeaderAction} from '../header/header-action';
import {SaveType} from '../header/save-type.enum';
import {TabsMenuItem} from '../tabs-menu/tabs-menu-item';

@Component({
  selector: 'vdw-editor-header',
  templateUrl: './editor-header.component.html',
  styleUrls: ['./editor-header.component.scss']
})
export class EditorHeaderComponent implements OnInit, OnChanges {
  @Input() public menuItems: TabsMenuItem[];
  @Input() public selectedMenuItem: TabsMenuItem;
  @Input() public identifier: string;
  @Input() public canShowCancel = true;
  @Input() public canShowSave = true;
  @Input() public canShowSaveAndClose = true;
  @Input() public canShowSaveAndCreateNew = true;
  @Input() public canShowDuplicate = true;
  @Input() public canShowDelete = true;
  @Input() public canShowSettings = true;
  @Input() public actionText = 'ANGULAR_COMPONENT_LIBRARY.HEADER.SAVE';
  @Input() public customExtraActions: HeaderAction[];
  @Input() public isCreateNewPage = false;
  @Input() public inProgress = false;
  @Input() public disableSaveButton = false;
  @Output() public selectedMenuItemChange = new EventEmitter<TabsMenuItem>();
  @Output() public saveEvent = new EventEmitter<void>();
  @Output() public saveAndCloseEvent = new EventEmitter<void>();
  @Output() public saveAndCreateNewEvent = new EventEmitter<void>();
  @Output() public duplicateEvent = new EventEmitter<void>();
  @Output() public deleteEvent = new EventEmitter<void>();
  @Output() public settingsEvent = new EventEmitter<void>();
  @Output() public cancelEvent = new EventEmitter<void>();

  public canShowExtraActions = false;
  public defaultSaveType: SaveType;
  public readonly SAVE = SaveType.SAVE;
  public readonly SAVE_AND_CLOSE = SaveType.SAVE_AND_CLOSE;
  public readonly SAVE_AND_CREATE_NEW = SaveType.SAVE_AND_CREATE_NEW;
  private readonly LOCAL_STORAGE_KEY = 'saveType';

  public constructor(private readonly localStorage: LocalStorageService) {}

  public ngOnInit(): void {
    this.setDefaultSaveType(this.identifier);
    this.canShowExtraActions = this.getShowExtraActions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!AssertionUtils.isNullOrUndefined(changes.identifier) && !changes.identifier.isFirstChange()) {
      this.setDefaultSaveType(changes.identifier.currentValue);
    }
  }

  public getSaveButtonTranslationKey(): string {
    switch (this.defaultSaveType) {
      case SaveType.SAVE_AND_CLOSE:
        return 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CLOSE';
      case SaveType.SAVE_AND_CREATE_NEW:
        return 'ANGULAR_COMPONENT_LIBRARY.HEADER.ACTION_AND_CREATE_NEW';
      default:
        return this.actionText;
    }
  }

  public canShowSaveMenuButton(): boolean {
    return this.canShowSave && this.defaultSaveType !== SaveType.SAVE;
  }

  public canShowSaveAndCloseMenuButton(): boolean {
    return this.canShowSaveAndClose && this.defaultSaveType !== SaveType.SAVE_AND_CLOSE;
  }

  public canShowSaveAndCreateNewMenuButton(): boolean {
    return this.canShowSaveAndCreateNew && this.defaultSaveType !== SaveType.SAVE_AND_CREATE_NEW;
  }

  public canShowSplitButton(): boolean {
    return this.canShowSaveMenuButton() || this.canShowSaveAndCloseMenuButton() || this.canShowSaveAndCreateNewMenuButton();
  }

  private getShowExtraActions(): boolean {
    return this.canShowSettings || ((this.canShowDuplicate || this.canShowDelete || this.customExtraActions?.length > 0) && !this.isCreateNewPage);
  }

  public save(saveType: SaveType): void {
    if (this.identifier) {
      this.defaultSaveType = saveType;
      this.localStorage.set(`${this.LOCAL_STORAGE_KEY}.${this.identifier}`, this.defaultSaveType);
    }

    if (saveType === SaveType.SAVE) {
      this.saveEvent.emit();
    } else if (saveType === SaveType.SAVE_AND_CLOSE) {
      this.saveAndCloseEvent.emit();
    } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
      this.saveAndCreateNewEvent.emit();
    }
  }

  private setDefaultSaveType(identifier: string): void {
    const defaultSaveType = this.localStorage.get<SaveType>(`${this.LOCAL_STORAGE_KEY}.${identifier}`);

    if (!AssertionUtils.isNullOrUndefined(identifier) && this.isSaveTypeButtonAvailable(defaultSaveType)) {
      this.defaultSaveType = defaultSaveType;
    } else if (this.canShowSave) {
      this.defaultSaveType = SaveType.SAVE;
    } else if (this.canShowSaveAndClose) {
      this.defaultSaveType = SaveType.SAVE_AND_CLOSE;
    } else if (this.canShowSaveAndCreateNew) {
      this.defaultSaveType = SaveType.SAVE_AND_CREATE_NEW;
    }
  }

  private isSaveTypeButtonAvailable(saveType: SaveType): boolean {
    switch (saveType) {
      case SaveType.SAVE:
        return this.canShowSave;
      case SaveType.SAVE_AND_CLOSE:
        return this.canShowSaveAndClose;
      case SaveType.SAVE_AND_CREATE_NEW:
        return this.canShowSaveAndCreateNew;
      default:
        return false;
    }
  }
}
