import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {CustomSettings} from '@domain/custom-settings/custom-settings';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {CustomSettingsRepository} from '../custom-settings-repository';

@Injectable({providedIn: 'root'})
export class HttpPlasticProductsCustomSettingsService extends CustomSettingsRepository<CustomSettings, PlasticProduct> {
  public constructor(
    httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {
    super(httpClient, `${backendConfiguration.getEndpoint()}plastic-products/custom-settings`);
  }
}
