import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {DateRange, MatDatepickerContent, MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {DateTimePipeModule} from '../../custom-pipes/date-time-pipe/date-time-pipe.module';
import {TranslationModule} from '../../translation/translation.module';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {DateRangePickerComponent} from './date-range-picker/date-range-picker.component';
import {DatepickerHeaderComponent} from './datepicker-header/datepicker-header.component';

/**
 * Custom fix to circumvent assumptions made by Angular Material developers about having additional buttons for a date-picker.
 *
 * By default, when buttons are added to the date-picker, the changes are not propagated, and the date-picker does not close automatically.
 * This is because they expect a 'confirm' button to be added. At time of writing, there is no supported way to add buttons while preserving the default behavior.
 * Original code can be found [here](https://github.com/angular/components/blob/22b68e0f20421f0c0fbc09cdaa1cc2614afbeaeb/src/material/datepicker/datepicker-base.ts#L227).
 *
 * Since this is a private method, this functionality should be reviewed whenever we update Angular Material.
 */
function replaceHandleUserSelection(event: any): void {
  const selection = this._model.selection;
  const value = event.value;
  const isRange = selection instanceof DateRange;
  if (isRange && this._rangeSelectionStrategy) {
    const newSelection = this._rangeSelectionStrategy.selectionFinished(value, selection, event.event);
    this._model.updateSelection(newSelection, this);
  } else if (value && (isRange || !this._dateAdapter.sameDate(value, selection))) {
    this._model.add(value);
  }
  // added to propagate changes automatically from _model to _globalModel
  this._applyPendingSelection();
  // removed check that prevents closing when picker has an actions template
  if (!this._model || this._model.isComplete()) {
    this.datepicker.close();
  }
}

MatDatepickerContent.prototype._handleUserSelection = replaceHandleUserSelection;

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatButtonModule, TranslationModule, MatDatepickerModule, MatFormFieldModule, ReactiveFormsModule, DateTimePipeModule],
  exports: [DatepickerHeaderComponent, DatePickerComponent, DateRangePickerComponent],
  declarations: [DatepickerHeaderComponent, DatePickerComponent, DateRangePickerComponent]
})
export class DatePickerModule {}
