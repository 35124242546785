import {Route} from '@angular/router';
import {calendarRoutes} from '@presentation/pages/calendar/calendar.routing';
import {patternRoutes} from '@presentation/pages/calendar/pattern/pattern.routing';
import {articleRoutes} from '@presentation/pages/texfab/article/article.routing';
import {customerRoutes} from '@presentation/pages/texfab/customer/customer.routing';
import {orderbookRoutes} from '@presentation/pages/texfab/orderbook/orderbook.routing';
import {pathLayoutTemplateRoutes} from '@presentation/pages/texfab/path-layout-template/path-layout-template.routing';
import {planningPrototypeRoutes} from '@presentation/pages/texfab/planning-prototype/planning-prototype.routing';
import {planningRoutes} from '@presentation/pages/texfab/planning/planning.routing';
import {plasticProductionOrderRoutes} from '@presentation/pages/texfab/plastic-production-order/plastic-production-order.routing';
import {productionOrderWeavingRoutes} from '@presentation/pages/texfab/production-order-lite/production-order-weaving.routing';
import {productionScheduleRoutes} from '@presentation/pages/texfab/production-schedule/production-schedule.routing';
import {salesOrderRoutes} from '@presentation/pages/texfab/sales-order/sales-order.routing';
import {texfabRoutes} from '@presentation/pages/texfab/texfab.routing';
import {tuftProductionOrderRoutes} from '@presentation/pages/texfab/tuft-production-order/tuft-production-order.routing';
import {plasticProductRoutes} from '@presentation/pages/textile-data/plastic-product/plastic-product.routing';
import {textileDataRoutes} from '@presentation/pages/textile-data/textile-data.routing';
import {toolRoutes} from '@presentation/pages/utilities/tool/tool.routing';
import {utilitiesRoutes} from '@presentation/pages/utilities/utilities.routing';
import {PrototypeRouteUtils} from '@vdw/angular-component-library';
import {routes} from '../../../app.routing';
import {NavigationId} from './navigation-id.enum';
import {RouteData} from './route-data.interface';

export class NavigationUtils {
  private static readonly cachedRoutes = [
    ...routes,
    ...texfabRoutes,
    ...planningRoutes,
    ...planningPrototypeRoutes,
    ...productionScheduleRoutes,
    ...customerRoutes,
    ...articleRoutes,
    ...salesOrderRoutes,
    ...orderbookRoutes,
    ...productionOrderWeavingRoutes,
    ...tuftProductionOrderRoutes,
    ...plasticProductionOrderRoutes,
    ...pathLayoutTemplateRoutes,
    ...textileDataRoutes,
    ...utilitiesRoutes,
    ...toolRoutes,
    ...plasticProductRoutes,
    ...calendarRoutes,
    ...patternRoutes
  ];

  public static getNavigationRoute(id: NavigationId): Route {
    return NavigationUtils.cachedRoutes.find((route: Route) => route.data?.id === id);
  }

  public static getNavigationData(id: NavigationId): RouteData {
    return NavigationUtils.cachedRoutes.find((route: Route) => route.data?.id === id)?.data as RouteData;
  }

  public static getMultiNavigationData(ids: NavigationId[]): RouteData[] {
    return NavigationUtils.cachedRoutes.filter((route: Route) => ids.includes(route.data?.id)).map((route: Route) => route?.data as RouteData);
  }

  public static getAbsoluteNavigationData(id: NavigationId): RouteData {
    const item = NavigationUtils.cachedRoutes.find((route: Route) => route.data?.id === id)?.data as RouteData;

    return {
      id: item.id,
      icon: item.icon,
      reuse: item.reuse,
      parentId: item.parentId,
      navigationKey: item.navigationKey,
      route: NavigationUtils.getAbsolutePath(id),
      requiredPermission: item.requiredPermission
    } as RouteData;
  }

  public static getRoute(id: NavigationId): string {
    return (NavigationUtils.cachedRoutes.find((route: Route) => route.data?.id === id)?.data as RouteData).route;
  }

  public static getAbsolutePath(id: NavigationId): string {
    return PrototypeRouteUtils.getAbsolutePath(NavigationUtils.cachedRoutes, id);
  }
}
