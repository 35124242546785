import {WeavePattern} from '@domain/textile-data/weave-structure/weave-pattern/weave-pattern';
import {WeaveQualityType} from '@presentation/pages/textile-data/machine-quality/overview/weave-quality-type.enum';
import {StringUtils} from '@vdw/angular-component-library';

export class OverviewListWeaveStructure {
  public id: number;
  public name: string;
  public version: number;
  public type: string;
  public description: string;
  public fileName: string;
  public fromVandewiele: boolean;
  public patterns: WeavePattern[];
  public qualityType: WeaveQualityType;
  public releaseNotes: string;
  public dateModified: Date;
  public picksPerColorLine: number;

  public constructor(
    id: number,
    name: string,
    version: number,
    type: string,
    description: string,
    fileName: string,
    fromVandewiele: boolean,
    qualityType: WeaveQualityType,
    releaseNotes: string,
    weavePatterns: WeavePattern[] = [],
    dateModified?: Date,
    picksPerColorLine?: number
  ) {
    this.id = id;
    this.name = name;
    this.version = version;
    this.type = type;
    this.description = description;
    this.fileName = fileName;
    this.fromVandewiele = fromVandewiele;
    this.patterns = weavePatterns;
    this.qualityType = qualityType;
    this.releaseNotes = releaseNotes;
    this.dateModified = dateModified;
    this.picksPerColorLine = picksPerColorLine;
  }

  public static fromJSON(weaveStructureJSON: any): OverviewListWeaveStructure {
    return new OverviewListWeaveStructure(
      weaveStructureJSON.id,
      weaveStructureJSON.name,
      weaveStructureJSON.version,
      weaveStructureJSON.type,
      weaveStructureJSON.description,
      weaveStructureJSON.fileName,
      weaveStructureJSON.fromVandewiele,
      weaveStructureJSON.qualityType,
      weaveStructureJSON.releaseNotes,
      weaveStructureJSON.patterns,
      StringUtils.toDate(weaveStructureJSON.dateModified),
      weaveStructureJSON.picksPerColorLine
    );
  }

  public toJSON(): JSON {
    return {
      id: this.id,
      name: this.name,
      version: this.version,
      type: this.type,
      description: this.description,
      fileName: this.fileName,
      fromVandewiele: this.fromVandewiele,
      patterns: this.patterns,
      releaseNotes: this.releaseNotes
    } as any as JSON;
  }

  public canDuplicate(): boolean {
    return this.fromVandewiele;
  }

  public getReasonForPreventingDuplicate(): string {
    return this.canDuplicate() ? '' : 'TEXTILE_DATA.WEAVE_STRUCTURE.ERRORS.PREVENT_DUPLICATE';
  }
}
