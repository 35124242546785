import {Component, Input} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ContentSwitcherDialogService} from '../../custom-components/content-switcher/content-switcher-dialog.service';
import {ContentSwitcherEntry} from '../../custom-components/content-switcher/content-switcher-entry.interface';

@Component({
  selector: 'vdw-invalid-form-message',
  templateUrl: './invalid-form-message.component.html',
  styleUrls: ['./invalid-form-message.component.scss']
})
export class InvalidFormMessageComponent {
  @Input() public message = 'ANGULAR_COMPONENT_LIBRARY.FORM_MESSAGE.CHECK_INVALID_FIELDS';
  @Input() public compact = false;
  @Input() public link = null;
  @Input() public linkTranslationKey = 'GENERAL.ACTIONS.LOAD';
  @Input() public loadEntry: ContentSwitcherEntry;

  public constructor(private readonly contentSwitcher: ContentSwitcherDialogService) {}

  protected navigate(): void {
    this.contentSwitcher.navigateBack();
    this.contentSwitcher.navigateForward({key: 'placeholder', component: undefined, data: null});

    setTimeout(() => {
      this.contentSwitcher.navigateForward(this.loadEntry);
    }, 0);
  }

  protected isDialog(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog);
  }
}
