import {Component, HostBinding, Input} from '@angular/core';
import moment from 'moment';
import {Message} from '../../common/data/message';
import {MessageType} from '../../common/data/message-type.enum';
import {DateTimeFormat} from '../../common/datetime-format.enum';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {DateTimePipe} from '../../custom-pipes/date-time-pipe/date-time.pipe';

@Component({
  selector: 'vdw-message-container',
  templateUrl: './message-container.component.html',
  styleUrls: ['./message-container.component.scss']
})
export class MessageContainerComponent {
  @Input() public showTitle = false;
  @Input() public showTimestamp = false;
  @Input() public onlyShowTitle = false;
  @Input() public messages: Message[];
  @Input() public title: string;
  @Input() public currentSubscriptionTimezoneOffset: string;

  @HostBinding('class.error') public get isErrorMessage(): boolean {
    return this.messages[0].type === MessageType.ERROR;
  }

  @HostBinding('class.warning') public get isWarningMessage(): boolean {
    return this.messages[0].type === MessageType.WARNING;
  }

  @HostBinding('class.info') public get isInfoMessage(): boolean {
    return this.messages[0].type === MessageType.INFO;
  }

  public constructor(private readonly dateTimePipe: DateTimePipe) {}

  public canShowMessageList(): boolean {
    return this.showTitle || this.messages.length > 1;
  }

  public canShowTimestampForEachMessage(): boolean {
    return !this.showTitle && this.showTimestamp;
  }

  public getCurrentDateForSubscriptionTimezone(timestamp: Date): string {
    const timestampWithOffset = AssertionUtils.isNullOrUndefined(this.currentSubscriptionTimezoneOffset) ? moment(timestamp) : moment(timestamp).utcOffset(this.currentSubscriptionTimezoneOffset);
    return this.dateTimePipe.transform(timestampWithOffset.toDate(), DateTimeFormat.DAY_MONTH_SHORT_TIME_WITH_SECONDS);
  }
}
