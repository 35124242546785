import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {IdNameUsed} from '@domain/id-name-used';
import {MultiDeleteResponse} from '@domain/textile-data/multi-delete-reponse';
import {Repository} from '@infrastructure/http/repository';
import {CheckName, Conflict} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpRawMaterialsService implements Repository<IdNameUsed, IdNameUsed> {
  private httpClient: HttpClient;
  private backendConfiguration: BackendConfiguration;

  public constructor(httpClient: HttpClient, backendConfiguration: BackendConfiguration) {
    this.httpClient = httpClient;
    this.backendConfiguration = backendConfiguration;
  }

  public getById(id: number): Observable<IdNameUsed> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}raw-materials/${id}`).pipe(map((response: any) => IdNameUsed.fromJSON(response)));
  }

  public save(item: IdNameUsed): Observable<number> {
    return this.httpClient.post(`${this.backendConfiguration.getEndpoint()}raw-materials`, item.toJSON()).pipe(map((response: {id: number}) => response.id));
  }

  public update(item: IdNameUsed): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getEndpoint()}raw-materials/${item.id}`, item.toJSON());
  }

  public getAll(): Observable<IdNameUsed[]> {
    return this.httpClient.get<IdNameUsed[]>(`${this.backendConfiguration.getEndpoint()}raw-materials`);
  }

  public delete(id: number | string): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getEndpoint()}raw-materials/${id}`);
  }

  public deleteMulti(ids: number[]): Observable<MultiDeleteResponse[]> {
    return this.httpClient
      .delete(`${this.backendConfiguration.getEndpoint()}raw-materials/multi-delete`, {body: ids})
      .pipe(map((reponsesJSON: any) => reponsesJSON.map((reponseJSON: any) => MultiDeleteResponse.fromJSON(reponseJSON))));
  }

  public isIdentifierAvailable(name: string): Observable<CheckName> {
    const params = new HttpParams().set('name', name);

    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}raw-materials/check-name`, {params}).pipe(map((response: any) => CheckName.fromJSON(response)));
  }

  public getConflicts(id: number): Observable<Conflict[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}raw-materials/${id}/conflicts`)
      .pipe(map((conflictsJSON: any) => conflictsJSON.map((conflictJSON: any) => Conflict.fromJSON(conflictJSON))));
  }
}
