export enum DateTimeFormat {
  TIME_SIMPLE = 'TIME_SIMPLE',
  TIME_WITH_SECONDS = 'TIME_WITH_SECONDS',
  DATETIME_SHORT = 'DATETIME_SHORT',
  DATE_SHORT = 'DATE_SHORT',
  DATETIME_SHORT_WITH_SECONDS = 'DATETIME_SHORT_WITH_SECONDS',
  DATE_HUGE = 'DATE_HUGE',
  DAY_WEEKDAY = 'DAY_WEEKDAY',
  DATETIME_MED_WITH_WEEKDAY = 'DATETIME_MED_WITH_WEEKDAY',
  MONTH_YEAR = 'MONTH_YEAR',
  DAY_MONTH_SHORT_TIME_WITH_SECONDS = 'DAY_MONTH_SHORT_TIME_WITH_SECONDS'
}
