<div class="flex-display grid">
  <div #scrollContainer class="scroll-container bottom-border" (scroll)="scrolling($event)" (mousewheel)="onMouseWheelInput($event)">
    <div #bodyContainer class="body-container">
      <div class="drag-container">
        <div #dragContainer></div>
      </div>
      <div class="sticky-top-element top-container">
        <div class="header-row">
          <div class="corner-cell">
            <h4 class="title-text">{{ rowTitle }}</h4>
          </div>
          <vdw-date-header-row *ngIf="timeView === 'DAY'"></vdw-date-header-row>
          <vdw-hour-header-row *ngIf="timeView === 'HOUR'" class="column-row" (selectedHourChanged)="onSelectedHourChanged($event)"></vdw-hour-header-row>
        </div>
        <div class="header-row">
          <div class="corner-cell"></div>
          <div class="flex-column relative">
            <div class="column-row duration-row">
              <div class="day-column" *ngFor="let dayCell of dayCellData" [style.flex-grow]="dayCell.duration">
                <div class="day-column duration-column" *ngFor="let timeCell of dayCell.items" [style.flex-grow]="timeCell.duration">
                  <p class="duration-text b1">{{ timeCell.date | DateTime: 'TIME_SIMPLE' }}</p>
                </div>
              </div>
            </div>
            <vdw-current-time-indicator></vdw-current-time-indicator>
          </div>
        </div>
      </div>
      <div class="column-container flex-display">
        <div [style.width.px]="ROW_INDICATOR_WIDTH"></div>
        <div class="column-row row-borders">
          <div class="day-column" *ngFor="let dayCell of dayCellData" [style.flex-grow]="dayCell.duration">
            <div class="day-column duration-column" *ngFor="let timeCell of dayCell.items" [style.flex-grow]="timeCell.duration"></div>
          </div>
        </div>
      </div>
      <div class="flex-display row-indicator-row" *ngFor="let rowIndicator of rowIndicators; index as rowIndex">
        <div class="sticky-left-element row-indicator bottom-border" [style.width.px]="ROW_INDICATOR_WIDTH">
          <div *ngTemplateOutlet="rowIndicator.template"></div>
        </div>
        <div class="planning-item-row bottom-border" (click)="onGridSelected(rowIndex, $event)" [attr.data-rowIndex]="rowIndex" [style.backgroundColor]="rowIndicator.rowBackgroundColor">
          <vdw-planning-item-display
            *ngFor="let planningItem of rowIndicator.data.items"
            [planningItem]="planningItem"
            vdwTimelinePosition
            [parentStartDate]="startDate"
            [parentEndDate]="endDate"
            [data]="planningItem"
            class="planning-item {{ planningItem.class }}"
          >
          </vdw-planning-item-display>
        </div>
      </div>
    </div>
  </div>
</div>
