import {EventEmitter, Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {ContentSwitcherUtils} from '@application/helper/utilities/content-switcher/content-switcher-utils';
import {IdNameUsed} from '@domain/id-name-used';
import {Permission} from '@domain/profile/permission.enum';
import {PropertyValue} from '@domain/property-value';
import {HttpRawMaterialsService} from '@infrastructure/http/raw-materials/http-raw-materials.service';
import {
  AssertionUtils,
  ContentSwitcherDialogService,
  CrudGridContextService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  CrudOverviewDataPageComponent,
  DialogBuilderFactoryService,
  DialogType,
  GridButtonClickedEvent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {TextileService} from '../textile-data-overview/textile.service';
import {TextileDataType} from '../textile-data-type.enum';
import {MaterialOverviewService} from './material-overview.service';

@Injectable()
export class MaterialSelectionService {
  private config: CrudOverviewDataConfig<IdNameUsed>;
  private readonly reloadData = new EventEmitter<PropertyValue[]>();
  private previouslySelectedObjects: IdNameUsed[];

  public constructor(
    private readonly injector: Injector,
    private readonly materials: HttpRawMaterialsService,
    private readonly translate: TranslateService,
    private readonly overview: MaterialOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<IdNameUsed>,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService,
    private readonly materialService: HttpRawMaterialsService,
    private readonly crudGridContextService: CrudGridContextService
  ) {
    this.init();
  }

  public init(): void {
    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL')
      .withRowData(this.materials.getAll())
      .withContentSwitcher(ContentSwitcherUtils.getAddRawMaterialEntry())
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsWithEntries(
          ContentSwitcherUtils.getEditRawMaterialEntry(),
          null,
          Permission.TEXSTYLE_RAW_MATERIAL_EDIT,
          ({gridApi, rowNode}: GridButtonClickedEvent) => {
            const data = AssertionUtils.isNullOrUndefined(rowNode) ? (gridApi?.getSelectedRows()[0] as IdNameUsed) : (rowNode.data as IdNameUsed);

            if (!AssertionUtils.isEmpty(this.previouslySelectedObjects) && data.id === this.previouslySelectedObjects[0]?.id) {
              this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
                titleText: this.translate.instant('CONFLICTS.DIALOG.IN_USE', {object: data.name}),
                messageText: this.translate.instant('CONFLICTS.DIALOG.DESELECT'),
                type: DialogType.DELETE
              });
              return;
            }

            this.textileService.removeConfirmation(
              AssertionUtils.isNullOrUndefined(rowNode) ? gridApi?.getSelectedRows()[0] : rowNode.data,
              TextileDataType.RAW_MATERIAL,
              false,
              null,
              this.materialService,
              null,
              null,
              this.reloadData,
              null,
              false,
              null,
              null,
              null,
              gridApi,
              true
            );
          }
        )
      )
      .withGridOptions(this.overview.getColumnDefs(), GridIdentifier.MATERIAL_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        this.overview.getDefaultGridOptionsBuilder(builder).withRowSelection(false, false, true, false).build()
      )
      .build();
  }

  public openContentSwitcherDialog(inputValue: IdNameUsed, data: any): Observable<IdNameUsed[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }

    this.config.selectedObjects = inputValue ? [inputValue] : [];
    this.previouslySelectedObjects = inputValue ? [inputValue] : [];

    const entry = {key: 'raw-material', component: CrudOverviewDataPageComponent, data: null};

    this.contentSwitcher.navigateForward(entry, {config: this.config});
    return this.builderFactoryService.openContentSwitcherDialog();
  }

  public setSelected(inputValue: IdNameUsed): void {
    this.config.selectedObjects = [inputValue];
  }
}
