import {IdName} from '@domain/id-name';
import {ProductionOrderYield} from '@domain/planning/production-order-yield';
import {ProductionScheduleState} from '@domain/production-schedule/production-schedule-state';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {MachineQualityForOverviewListProductionSchedule} from '@presentation/pages/texfab/production-schedule/overview/machine-quality-for-overview-list-production-schedule';
import {OverviewListColorSet} from '@presentation/pages/textile-data/color-set/overview/overview-list-color-set';
import {StringUtils} from '@vdw/angular-component-library';
import {includes, map} from 'lodash-es';
import {MachineForOverviewListProductionSchedule} from './machine-for-overview-list-production-schedule';

export class OverviewListProductionSchedule {
  private _id: string | number;
  private _name: string;
  private _status: ProductionScheduleStatus;
  private _state: ProductionScheduleState;
  private _machine: MachineForOverviewListProductionSchedule;
  private _machineQuality: MachineQualityForOverviewListProductionSchedule;
  private _coloredYarnSets: OverviewListColoredYarnSetWithStartDent[];
  private _dateCreated: Date;
  private _dueDate: Date;
  private _yield: ProductionOrderYield;
  private _mappingForCurrentPositionOnMachine: CreelMap[];
  private _pathWidthsInMM: number;
  private _pathWidthsInDents: number;
  private _weftDensity: number;
  private _lengthInMM: number;
  private _lengthInPicks: number;
  private _buggy1: string;
  private _buggy2: string;
  private _numberOfPaths: number;
  private _creel: IdName;
  private _dateModified: Date;
  private _comment: string;
  private _missingDesigns: boolean;
  private _colorSets: OverviewListColorSet[];
  private _isSample: boolean;
  private _practicalPickDensityInPicksPerMM: number;
  private _widthInMM: number;
  private _widthInDents: number;
  private _wovenStartDate: Date;
  private _wovenEndDate: Date;

  private readonly statusesRequiredToRemoveMachineFiles: ProductionScheduleStatus[] = [
    ProductionScheduleStatus.EXECUTED,
    ProductionScheduleStatus.EXECUTED_COMPLETE,
    ProductionScheduleStatus.EXECUTED_PARTIAL
  ];

  public constructor(
    id: string | number,
    name: string,
    status: ProductionScheduleStatus,
    state: ProductionScheduleState,
    machine: MachineForOverviewListProductionSchedule,
    machineQuality: MachineQualityForOverviewListProductionSchedule,
    coloredYarnSets: OverviewListColoredYarnSetWithStartDent[],
    dateCreated: Date,
    dueDate: Date,
    yieldOfProductionSchedule: ProductionOrderYield,
    mappingForCurrentPositionOnMachine: CreelMap[],
    pathWidthsInMM: number,
    pathWidthsInDents: number,
    weftDensity: number,
    lengthInMM: number,
    lengthInPicks: number,
    buggy1: string,
    buggy2: string,
    numberOfPaths: number,
    creel: IdName,
    dateModified: Date,
    comment: string,
    missingDesigns: boolean,
    colorSets: OverviewListColorSet[],
    isSample: boolean,
    practicalPickDensityInPicksPerMM: number,
    widthInMM: number,
    widthInDents: number,
    wovenStartDate: Date,
    wovenEndDate: Date
  ) {
    this._id = id;
    this._name = name;
    this._status = status;
    this._state = state;
    this._machine = machine;
    this._machineQuality = machineQuality;
    this._coloredYarnSets = coloredYarnSets;
    this._dateCreated = dateCreated;
    this._dueDate = dueDate;
    this._yield = yieldOfProductionSchedule;
    this._mappingForCurrentPositionOnMachine = mappingForCurrentPositionOnMachine;
    this._pathWidthsInMM = pathWidthsInMM;
    this._pathWidthsInDents = pathWidthsInDents;
    this._weftDensity = weftDensity;
    this._lengthInMM = lengthInMM;
    this._lengthInPicks = lengthInPicks;
    this._buggy1 = buggy1;
    this._buggy2 = buggy2;
    this._numberOfPaths = numberOfPaths;
    this._creel = creel;
    this._dateModified = dateModified;
    this._comment = comment;
    this._missingDesigns = missingDesigns;
    this._colorSets = colorSets;
    this._isSample = isSample;
    this._practicalPickDensityInPicksPerMM = practicalPickDensityInPicksPerMM;
    this._widthInMM = widthInMM;
    this._widthInDents = widthInDents;
    this._wovenStartDate = wovenStartDate;
    this._wovenEndDate = wovenEndDate;
  }

  public get id(): string | number {
    return this._id;
  }

  public set id(value: string | number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public get state(): ProductionScheduleState {
    return this._state;
  }

  public set state(value: ProductionScheduleState) {
    this._state = value;
  }

  public get machine(): MachineForOverviewListProductionSchedule {
    return this._machine;
  }

  public get machineQuality(): MachineQualityForOverviewListProductionSchedule {
    return this._machineQuality;
  }

  public get coloredYarnSets(): OverviewListColoredYarnSetWithStartDent[] {
    return this._coloredYarnSets;
  }

  public get dateCreated(): Date {
    return this._dateCreated;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public get yield(): ProductionOrderYield {
    return this._yield;
  }

  public get mappingForCurrentPositionOnMachine(): CreelMap[] {
    return this._mappingForCurrentPositionOnMachine;
  }

  public get pathWidthsInMM(): number {
    return this._pathWidthsInMM;
  }

  public get pathWidthsInDents(): number {
    return this._pathWidthsInDents;
  }

  public get weftDensity(): number {
    return this._weftDensity;
  }

  public get lengthInMM(): number {
    return this._lengthInMM;
  }

  public get lengthInPicks(): number {
    return this._lengthInPicks;
  }

  public get buggy1(): string {
    return this._buggy1;
  }

  public get buggy2(): string {
    return this._buggy2;
  }

  public get numberOfPaths(): number {
    return this._numberOfPaths;
  }

  public get creel(): IdName {
    return this._creel;
  }

  public get dateModified(): Date {
    return this._dateModified;
  }

  public get comment(): string {
    return this._comment;
  }

  public get missingDesigns(): boolean {
    return this._missingDesigns;
  }

  public get colorSets(): OverviewListColorSet[] {
    return this._colorSets;
  }

  public get isSample(): boolean {
    return this._isSample;
  }

  public get practicalPickDensityInPicksPerMM(): number {
    return this._practicalPickDensityInPicksPerMM;
  }

  public get widthInMM(): number {
    return this._widthInMM;
  }

  public get widthInDents(): number {
    return this._widthInDents;
  }

  public get wovenStartDate(): Date {
    return this._wovenStartDate;
  }

  public set wovenStartDate(value: Date) {
    this._wovenStartDate = value;
  }

  public get wovenEndDate(): Date {
    return this._wovenEndDate;
  }

  public set wovenEndDate(value: Date) {
    this._wovenEndDate = value;
  }

  public static fromJSON(overviewListProductionScheduleJSON: any): OverviewListProductionSchedule {
    return new OverviewListProductionSchedule(
      overviewListProductionScheduleJSON.id,
      overviewListProductionScheduleJSON.name,
      overviewListProductionScheduleJSON.status,
      overviewListProductionScheduleJSON.state ? ProductionScheduleState.fromJSON(overviewListProductionScheduleJSON.state) : null,
      MachineForOverviewListProductionSchedule.fromJSON(overviewListProductionScheduleJSON.machine),
      MachineQualityForOverviewListProductionSchedule.fromJSON(overviewListProductionScheduleJSON.machineQuality),
      map(overviewListProductionScheduleJSON.coloredYarnSets, (coloredYarnSetJSON: any) => {
        return OverviewListColoredYarnSetWithStartDent.fromJSON(coloredYarnSetJSON);
      }),
      StringUtils.toDate(overviewListProductionScheduleJSON.dateCreated),
      StringUtils.toDate(overviewListProductionScheduleJSON.dueDate),
      overviewListProductionScheduleJSON.yield,
      overviewListProductionScheduleJSON.mappingForCurrentPositionOnMachine,
      overviewListProductionScheduleJSON.pathWidthsInMM,
      overviewListProductionScheduleJSON.pathWidthsInDents,
      overviewListProductionScheduleJSON.machineQuality.weftDensity,
      overviewListProductionScheduleJSON.lengthInMM,
      overviewListProductionScheduleJSON.lengthInPicks,
      overviewListProductionScheduleJSON.buggy1,
      overviewListProductionScheduleJSON.buggy2,
      overviewListProductionScheduleJSON.numberOfPaths,
      IdName.fromJSON(overviewListProductionScheduleJSON.creel),
      StringUtils.toDate(overviewListProductionScheduleJSON.dateModified),
      overviewListProductionScheduleJSON.comment,
      overviewListProductionScheduleJSON.missingDesigns,
      overviewListProductionScheduleJSON.colorSets.map((colorSetJSON: any) => OverviewListColorSet.fromJSON(colorSetJSON)),
      overviewListProductionScheduleJSON.isSample,
      overviewListProductionScheduleJSON.practicalPickDensityInPicksPerMM,
      overviewListProductionScheduleJSON.widthInMM,
      overviewListProductionScheduleJSON.widthInDents,
      StringUtils.toDate(overviewListProductionScheduleJSON.wovenStartDate),
      StringUtils.toDate(overviewListProductionScheduleJSON.wovenEndDate)
    );
  }

  public canDuplicate(): boolean {
    return true;
  }

  public getReasonForPreventingDuplicate(): string {
    return this.canDuplicate() ? '' : 'PRODUCTION_ORDER.WARNINGS.PREVENT_DUPLICATE';
  }

  public canPrintWeavingOrder(): boolean {
    return this.isStatusAtleastProcessed() || this.hasToBeReconfirmed();
  }

  public canDownloadEpFiles(): boolean {
    return this.isStatusAtleastProcessed() || this.hasFailed();
  }

  public canRemoveMachineFiles(): boolean {
    return includes(this.statusesRequiredToRemoveMachineFiles, this.status);
  }

  public canBeConfirmed(): boolean {
    return this.status === ProductionScheduleStatus.VERIFIED || this.status === ProductionScheduleStatus.VERIFIED_WITH_WARNINGS || this.status === ProductionScheduleStatus.CREATED;
  }

  public hasFailed(): boolean {
    return this.status === ProductionScheduleStatus.FAILURE;
  }

  public canBeCompleted(): boolean {
    return this.status === ProductionScheduleStatus.TRANSLATED && this._machine.remoteMachine;
  }

  public hasToBeReconfirmed(): boolean {
    return this.status === ProductionScheduleStatus.TO_RECONFIRM;
  }

  private isStatusAtleastProcessed(): boolean {
    const productionScheduleStatuses: ProductionScheduleStatus[] = [
      ProductionScheduleStatus.PROCESSED,
      ProductionScheduleStatus.FINALIZING,
      ProductionScheduleStatus.FINALIZED,
      ProductionScheduleStatus.TRANSLATING,
      ProductionScheduleStatus.TRANSLATED,
      ProductionScheduleStatus.SENDING,
      ProductionScheduleStatus.ARRIVED,
      ProductionScheduleStatus.EXECUTING,
      ProductionScheduleStatus.EXECUTED,
      ProductionScheduleStatus.EXECUTED_COMPLETE,
      ProductionScheduleStatus.EXECUTED_PARTIAL,
      ProductionScheduleStatus.CANCELLED,
      ProductionScheduleStatus.QUEUING,
      ProductionScheduleStatus.QUEUED
    ];

    return includes(productionScheduleStatuses, this.status);
  }
}
