import {IdName} from './id-name';

export class IdNameUsed extends IdName {
  private readonly _used: boolean;

  public constructor(id: number, name: string, used: boolean) {
    super(id, name);
    this._used = used;
  }

  public get used(): boolean {
    return this._used;
  }

  public static fromJSON(object: any): IdNameUsed {
    return new IdNameUsed(object.id, object.name, object.used);
  }
}
