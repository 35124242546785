import {Injectable} from '@angular/core';
import {RouteData} from '@application/helper/routing/route-data.interface';
import {ContentSwitcherUtils} from '@application/helper/utilities/content-switcher/content-switcher-utils';
import {ColDefBuilderFactoryService, GridOptionsBuilder, LinkIconRendererComponent, NoDataOverlayComponentParams, OverlayComponentParams, TranslateService} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams} from 'ag-grid-community';

@Injectable()
export class StockLocationOverviewService {
  public constructor(
    private readonly colDefBuilder: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(withLinkIcon: boolean = true): ColDef[] {
    let nameColDef = this.colDefBuilder
      .getBuilder()
      .withField('name')
      .withHeaderName(this.translate.instant('UTILITIES.TOOL.STOCK_LOCATION', {count: 1}));

    if (withLinkIcon) {
      const editEntry = ContentSwitcherUtils.getEditStockLocationEntry();
      nameColDef = nameColDef.withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
        return {params, contentSwitcherEntry: {key: editEntry.key, component: editEntry.component, routeData: editEntry.data as RouteData}};
      });
    }

    return [this.colDefBuilder.getBuilder().withField('id').withHide().build(), nameColDef.build()];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({scale: 0.7} as OverlayComponentParams)
      .withNoRowsOverlay({scale: 0.7, titleParam: this.translate.instant('UTILITIES.TOOL.STOCK_LOCATION', {count: 1}), hideDescription: true} as NoDataOverlayComponentParams);
  }
}
