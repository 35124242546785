import {Injectable, Injector, Type} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {filter, Observable, of} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {DialogBuilderFactoryService} from '../../dialogs/dialog-builder/dialog-builder-factory.service';

@Injectable({providedIn: 'root'})
export class ContentSwitcherDialogPrototypeService<T = unknown> {
  public headerTitle: string;
  public parentInjector: Injector;
  public dialog: MatDialogRef<any>;

  public static contentSwitcherDialogComponentType: Type<any>;

  public constructor(private readonly dialogBuilderFactory: DialogBuilderFactoryService) {}

  public setHeaderTitle(title: string): void {
    this.headerTitle = title;
  }

  public openContentSwitcherDialog<TDialog extends T = T>(title: string = null): Observable<TDialog[]> {
    this.setHeaderTitle(title);

    return !AssertionUtils.isNullOrUndefined(this.dialog)
      ? of()
      : (this.dialogBuilderFactory
          .getBuilder()
          .withWidth('80vw')
          .withMinWidth(900)
          .withHeight('80vh')
          .withCloseDisabled()
          .withMinHeight(480)
          .openDialog(ContentSwitcherDialogPrototypeService.contentSwitcherDialogComponentType, {})
          .pipe(filter((data: unknown) => !AssertionUtils.isNullOrUndefined(data))) as Observable<TDialog[]>);
  }
}
